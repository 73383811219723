import React, { ReactElement } from 'react'

import './Icons.scss'

const IconSearch = (): ReactElement => (
  <svg className="IconSearch" viewBox="0 0 20 20">
    <path d="M20,18.3l-3.7-3.7c2.8-3.6,2.5-8.7-0.8-12C12-0.9,6.2-0.9,2.7,2.7c-3.6,3.6-3.6,9.3,0,12.9c1.8,1.8,4.1,2.7,6.5,2.7c2,0,3.9-0.6,5.6-1.9l3.7,3.7L20,18.3z M4.3,13.9c-2.6-2.6-2.6-6.9,0-9.6s6.9-2.6,9.6,0s2.6,6.9,0,9.6C11.3,16.6,7,16.6,4.3,13.9z" />
  </svg>
)

export default IconSearch
