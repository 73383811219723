import { combineReducers } from 'redux'

import events from './events/reducers'
import homepage from './homepage/reducers'
import members from './members/reducers'
import meta from './meta/reducers'
import navigation from './navigation/reducers'
import pages from './pages/reducers'
import ui from './ui/reducers'

export const rootReducer = combineReducers({
  events,
  homepage,
  members,
  meta,
  navigation,
  pages,
  ui,
})

export type RootState = ReturnType<typeof rootReducer>
