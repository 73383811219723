import React, { ReactElement } from 'react'
import classnames from 'classnames'

import './Text.scss'

type TextProps = {
  size?: string
  indent: boolean
  content: string
}

const Text = ({
  content,
  indent,
  size = 'normal',
}: TextProps): ReactElement => {
  const textClasses = classnames(
    'Text',
    { 'Text--indent': indent },
    `Text--size-${size}`
  )

  return (
    <div className={textClasses}>
      <div
        className="Text__inner"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  )
}

export default Text
