import React, { Fragment, ReactElement, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import moment from 'moment-timezone'

import {
  motionCalendarItemVariants,
  motionCalendarItemDetailVariants,
} from 'utils/motionVariants'
import Heading from 'view/components/heading/Heading'
import Image from 'view/components/image/Image'
import Text from 'view/components/text/Text'

import './CalendarItem.scss'

const CalendarItem = ({
  member,
  title,
  date,
  openingTime,
  closingTime,
  image,
  contents,
}): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const [active, setActive] = useState(false)
  const toggleActive = () => setActive(!active)

  const datePretty = moment(`${date} ${openingTime}`, 'DD.MM.YYYY hh:mm')
    .locale('de')
    .format('DD. MMMM YYYY')
  const timePretty = `${openingTime}${closingTime ? `–${closingTime}` : ``}`

  const img = image && image.length > 0 && (
    <figure className="CalendarItem__detail__left__figure">
      <div
        className="CalendarItem__detail__left__figure__ratio"
        style={{
          paddingTop: `${(image[0].height / image[0].width) * 100}%`,
        }}
      ></div>
      <Image
        image={image[0]}
        className="CalendarItem__detail__left__figure__img"
      />
    </figure>
  )

  const blocks =
    contents &&
    contents.map((item: any) => {
      const { id, type, hidden } = item

      if (hidden) {
        return null
      }

      if (type === 'eventsContents_text_BlockType') {
        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={false} />
            </Fragment>
          )
        } else {
          return <Text key={id} content={item.content} indent={false} />
        }
      } else if (type === 'eventsContents_textSmall_BlockType') {
        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={false} size="tiny" />
            </Fragment>
          )
        } else {
          return (
            <Text key={id} content={item.content} indent={false} size="tiny" />
          )
        }
      }

      return null
    })

  let locationText = null

  if (member) {
    const locationOnClick = (ev) => {
      ev.stopPropagation()

      navigate(`/detail/${member.slug}`, {
        state: {
          backgroundLocation: location,
        },
      })
    }

    locationText = (
      <button
        className="CalendarItem__teaser__location"
        onClick={locationOnClick}
      >
        → {member.title}
      </button>
    )
  }

  const buttonClasses = classnames('CalendarItem__teaser__button', {
    'CalendarItem__teaser__button--active': active,
  })

  return (
    <motion.div variants={motionCalendarItemVariants} className="CalendarItem">
      <div className="CalendarItem__teaser" onClick={toggleActive}>
        <div className="CalendarItem__teaser__col__1">{datePretty}</div>
        <div className="CalendarItem__teaser__col__2">{timePretty}</div>
        <div className="CalendarItem__teaser__col__3">{title}</div>
        <div className="CalendarItem__teaser__col__4">{locationText}</div>
        <div className="CalendarItem__teaser__col__5">
          <div className={buttonClasses}>
            <svg viewBox="0 0 15.38 8">
              <polygon points="0 0 7.69 8 15.38 0 0 0" />
            </svg>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {active && (
          <motion.div
            variants={motionCalendarItemDetailVariants}
            className="CalendarItem__detail"
            initial="initial"
            animate="visible"
            exit="exit"
          >
            <div className="CalendarItem__detail__grid">
              <div className="CalendarItem__detail__left">{img}</div>
              <div className="CalendarItem__detail__right">{blocks}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default CalendarItem
