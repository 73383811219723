import React, { ReactElement } from 'react'
import classnames from 'classnames'

import './Meta.scss'

type MetaProps = {
  content: string
  special?: boolean
}

const Meta = ({ content, special = false }: MetaProps): ReactElement => {
  const metaClasses = classnames('Meta', { 'Meta--special': special })

  return (
    <div className={metaClasses}>
      <div
        className="Meta__inner"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  )
}

export default Meta
