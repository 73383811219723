import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { IS_SERVER_SIDE } from 'Constants'
import { motionCalendarSectionVariants } from 'utils/motionVariants'
import CalendarItem from './CalendarItem'

import './CalendarSection.scss'

async function loadPolyfills() {
  if (!IS_SERVER_SIDE && typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

loadPolyfills()

const CalendarSection = ({ title, items, members }): ReactElement => {
  const titleMonth = title.split('_').shift()
  const titleYear = title.split('_').pop()
  const events = items.map((item) => {
    // find the member that matches the author of the current event
    const member = members.find((member) => member.author === item.author)

    return <CalendarItem key={item.id} member={member} {...item} />
  })

  return (
    <motion.div
      variants={motionCalendarSectionVariants}
      initial="initial"
      animate="visible"
      className="CalendarSection"
    >
      <div className="CalendarSection__title">
        <div className="CalendarSection__title__month">{titleMonth}</div>
        <div className="CalendarSection__title__year">&nbsp;{titleYear}</div>
      </div>
      <div className="CalendarSection__items">{events}</div>
    </motion.div>
  )
}

export default CalendarSection
