import React, { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { MemberType } from 'data/types'
import { motionSearchResultItemVariants } from 'utils/motionVariants'
import Image from 'view/components/image/Image'

import './SearchResultsItemMember.scss'

interface SearchResultsItemMemberProps extends MemberType {
  className?: string
  proximity: boolean
}

const SearchResultsItemMember = ({
  slug,
  title,
  thumbnail,
  tags,
  open,
  distance,
  proximity,
}: SearchResultsItemMemberProps): ReactElement => {
  const location = useLocation()

  const img = thumbnail && thumbnail.length > 0 && (
    <figure className="SearchResultsItemMember__img__figure">
      <div className="SearchResultsItemMember__img__figure__ratio" />
      <Image
        image={thumbnail[0]}
        className="SearchResultsItemMember__img__figure__img"
      />
    </figure>
  )

  const tagsText = tags.map((item) => item.title).join(', ')

  const svgCircle = (
    <svg version="1.1" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="6" />
    </svg>
  )

  let distanceText = null

  if (distance && proximity) {
    distanceText = `→ ${Math.round(distance)}M`
  }

  const availablity =
    open === null ? null : open ? (
      <>
        <span className="SearchResultsItemMember__availability__open">
          {svgCircle}
        </span>
        <span className="SearchResultsItemMember__availability__text">
          Open
        </span>
      </>
    ) : (
      <>
        <span className="SearchResultsItemMember__availability__closed">
          {svgCircle}
        </span>
        <span className="SearchResultsItemMember__availability__text">
          Closed
        </span>
      </>
    )

  return (
    <motion.div
      variants={motionSearchResultItemVariants}
      className="SearchResultsItemMember"
    >
      <Link
        className="SearchResultsItemMember__link"
        to={`/detail/${slug}`}
        state={{ backgroundLocation: location }}
      >
        <div className="SearchResultsItemMember__inner">
          <div className="SearchResultsItemMember__img">{img}</div>
          <div className="SearchResultsItemMember__title">{title}</div>
          <div className="SearchResultsItemMember__tags">{tagsText}</div>
          <div className="SearchResultsItemMember__availability">
            {availablity}
          </div>
          <div className="SearchResultsItemMember__distance">
            {distanceText}
          </div>
        </div>
      </Link>
    </motion.div>
  )
}

export default SearchResultsItemMember
