import { AnyAction } from 'redux'
import { shuffle } from 'underscore'

import { USER_MIN_DISTANCE } from 'Constants'
import { MembersStateType } from 'data/types'
import {
  MEMBERS_DATA_LOAD,
  MEMBERS_DISABLE_USER_INTERACTED_WITH_MAPS,
  MEMBERS_GET_USER_LOCATION,
  MEMBERS_GET_USER_LOCATION_FAILED,
  MEMBERS_SHUFFLE_HOME_ITEMS,
  MEMBERS_SHUFFLE_TAGS_ITEMS,
  MEMBERS_UPDATE_MAPS_LOCATION,
} from './types'
import { calculateDistance } from 'utils/geolocating'
import { calculateOpenStore } from 'utils/openStore'
import { getHomeItems, getMapsItems, getTags } from 'utils/redux'

const userMinDistance = parseInt(USER_MIN_DISTANCE, 10)

const initialState: MembersStateType = {
  items: [],
  itemsOpen: [],
  itemsClosed: [],
  itemsHome: [],
  itemsMap: [],
  tags: [],
  user: {
    lat: 0,
    lng: 0,
    locatingEnabled: false,
  },
  maps: {
    lat: 47.37661598554281,
    lng: 8.52518504281346,
  },
  proximity: false,
  minDistance: 0,
  userInteractedWithMaps: false,
}

export default function (
  state = initialState,
  action: AnyAction
): MembersStateType {
  switch (action.type) {
    // load data into redux
    case MEMBERS_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        let itemsOpen = []
        let itemsClosed = []

        const items = shuffle(data).map((item) => {
          const open = calculateOpenStore(item)
          const newItem = {
            ...item,
            open,
          }

          open ? itemsOpen.push(newItem) : itemsClosed.push(newItem)

          return newItem
        })

        return {
          ...state,
          items,
          itemsOpen,
          itemsClosed,
          itemsHome: getHomeItems(itemsOpen, itemsClosed),
          itemsMap: getMapsItems(itemsOpen, itemsClosed),
          tags: getTags(items, false),
        }
      }

      return state
    }

    case MEMBERS_GET_USER_LOCATION: {
      const { latitude, longitude } = action.payload

      let itemsOpen = []
      let itemsClosed = []
      let proximity = false
      let maps = { ...state.maps }
      let itemsMap = [...state.itemsMap]
      const distances = []

      let items = [...state.items].map((item) => {
        const { lng, lat } = item
        const open = calculateOpenStore(item)
        const distance = calculateDistance(lng, lat, longitude, latitude)

        item.open = open
        item.distance = distance

        open ? itemsOpen.push(item) : itemsClosed.push(item)
        distances.push(distance)

        return item
      })

      // calculate min distance
      const minDistance = Math.min(...distances)

      // only display by geolocation if within predefined radius
      if (minDistance < userMinDistance) {
        proximity = true

        // sort items by open and by distance
        items = items.sort((a, b) => {
          if (a.open === b.open) return a.distance - b.distance
          return a.open ? -1 : 1
        })

        // sort open and close items by distance
        itemsOpen = itemsOpen.sort((a, b) => a.distance - b.distance)
        itemsClosed = itemsClosed.sort((a, b) => a.distance - b.distance)

        // update maps position
        if (!state.userInteractedWithMaps) {
          maps = { lat: latitude, lng: longitude }
        }

        // udpate items for map
        itemsMap = getMapsItems(itemsOpen, itemsClosed)
      }

      return {
        ...state,
        items,
        itemsOpen,
        itemsClosed,
        itemsHome: getHomeItems(itemsOpen, itemsClosed),
        itemsMap,
        tags: getTags(items, proximity),
        user: {
          lat: latitude,
          lng: longitude,
          locatingEnabled: true,
        },
        maps,
        proximity,
        minDistance,
      }
    }

    case MEMBERS_GET_USER_LOCATION_FAILED: {
      return {
        ...state,
        user: {
          ...state.user,
          locatingEnabled: false,
        },
        proximity: false,
      }
    }

    case MEMBERS_DISABLE_USER_INTERACTED_WITH_MAPS: {
      let maps = { ...state.maps }

      if (state.proximity) {
        maps = { lat: state.user.lat, lng: state.user.lng }
      }

      return {
        ...state,
        maps,
        userInteractedWithMaps: false,
      }
    }

    case MEMBERS_UPDATE_MAPS_LOCATION: {
      const { latitude, longitude } = action.payload

      let itemsOpen = []
      let itemsClosed = []
      let items = [...state.items]

      items.map((item) => {
        const { lng, lat } = item
        const open = calculateOpenStore(item)
        const distanceMap = calculateDistance(lng, lat, longitude, latitude)
        const newItem = { ...item, open, distanceMap }

        open ? itemsOpen.push(newItem) : itemsClosed.push(newItem)
      })

      // sort open and close items by distance
      itemsOpen = itemsOpen.sort((a, b) => a.distanceMap - b.distanceMap)
      itemsClosed = itemsClosed.sort((a, b) => a.distanceMap - b.distanceMap)

      return {
        ...state,
        itemsMap: getMapsItems(itemsOpen, itemsClosed),
        maps: {
          lat: latitude,
          lng: longitude,
        },
        userInteractedWithMaps: state.proximity ? true : false,
      }
    }

    case MEMBERS_SHUFFLE_HOME_ITEMS: {
      if (!state.proximity) {
        let itemsOpen = []
        let itemsClosed = []

        const items = shuffle([...state.items]).map((item) => {
          const open = calculateOpenStore(item)
          const newItem = {
            ...item,
            open,
          }

          open ? itemsOpen.push(newItem) : itemsClosed.push(newItem)

          return newItem
        })

        return {
          ...state,
          items,
          itemsOpen,
          itemsClosed,
          itemsHome: getHomeItems(itemsOpen, itemsClosed),
        }
      }

      return {
        ...state,
      }
    }

    case MEMBERS_SHUFFLE_TAGS_ITEMS: {
      if (!state.proximity) {
        let itemsOpen = []
        let itemsClosed = []

        const items = shuffle([...state.items]).map((item) => {
          const open = calculateOpenStore(item)
          const newItem = {
            ...item,
            open,
          }

          open ? itemsOpen.push(newItem) : itemsClosed.push(newItem)

          return newItem
        })

        return {
          ...state,
          items,
          itemsOpen,
          itemsClosed,
          tags: getTags(items, false),
        }
      }

      return {
        ...state,
      }
    }

    default:
      return state
  }
}
