export const BUILD_NAME: string | undefined = process.env.RAZZLE_BUILD_NAME
export const BUILD_VERSION: string | undefined =
  process.env.RAZZLE_BUILD_VERSION

export const IS_DEV: boolean =
  process && process.env && process.env.NODE_ENV !== 'production'
export const IS_SERVER_SIDE: boolean = typeof window === 'undefined'

export const BASE_URL: string | undefined = process.env.RAZZLE_BASE_URL
export const BACKEND_URL: string | undefined = process.env.RAZZLE_BACKEND_URL
export const GRAPHQL_ENDPOINT: string | undefined =
  process.env.RAZZLE_GRAPHQL_ENDPOINT

export const CATEGORY_MAX: string | undefined = process.env.RAZZLE_CATEGORY_MAX
export const HOME_GRID_MAX: string | undefined =
  process.env.RAZZLE_HOME_GRID_MAX
export const MAPS_API_KEY: string | undefined = process.env.RAZZLE_MAPS_API_KEY
export const MAPS_LIST_MAX: string | undefined =
  process.env.RAZZLE_MAPS_LIST_MAX
export const USER_MIN_DISTANCE: string | undefined =
  process.env.RAZZLE_USER_MIN_DISTANCE

export const TIMEZONE = 'Europe/Zurich'
