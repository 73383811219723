export const motionButtonVariants = {
  hover: {
    scale: 1.2,
  },
  tap: {
    scale: 0.9,
  },
}

export const motionButtonDetailNavigationVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 90,
      damping: 11,
    },
  },
  hover: {
    scale: 1.2,
    opacity: 1,
  },
  tap: {
    scale: 0.9,
    opacity: 1,
  },
}

export const motionButtonMapVariants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 90,
      damping: 11,
    },
  },
  hover: {
    scale: 1.2,
    opacity: 1,
  },
  tap: {
    scale: 0.9,
    opacity: 1,
  },
}

export const motionCalendarSectionVariants = {
  initial: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.3,
      staggerChildren: 0.12,
      delayChildren: 0.4,
    },
  },
}

export const motionCalendarItemVariants = {
  initial: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 90,
      damping: 11,
    },
  },
}

export const motionCalendarItemDetailVariants = {
  initial: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
}

export const motionCalendarItemDetailTransition = {
  duration: 0.8,
  ease: [0.04, 0.62, 0.27, 0.98],
}

export const motionHeaderClaim = {
  hidden: { scale: 0, originX: 1, transitionEnd: { display: 'none' } },
  visible: { scale: 1, originX: 1, display: 'block' },
}

export const motionHeaderBg = {
  hidden: { opacity: 0, transitionEnd: { display: 'none' } },
  visible: { opacity: 1, display: 'block' },
}

export const motionHeaderBackdrop = {
  hidden: { opacity: 0, transitionEnd: { display: 'none' } },
  visible: { opacity: 1, display: 'block' },
}

export const motionMap = {
  hidden: { top: 'calc(100% - 48px)' },
  visible: { top: 0 },
}

export const motionMapDetail = {
  closed: { bottom: -136 },
  list: { bottom: 0 },
  item: { bottom: -36 },
}

export const motionMapDetailMobile = {
  closed: { bottom: 0 },
  list: { bottom: 0 },
  item: { bottom: -76 },
}

export const motionMapResetButton = {
  closed: { bottom: 120 },
  list: { bottom: 250 },
  item: { bottom: 220 },
}

export const motionMapResetButtonMobile = {
  closed: { bottom: 220 },
  list: { bottom: 220 },
  item: { bottom: 220 },
}

export const motionNavigationSmallVariants = {
  initial: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0.3,
    transition: {
      duration: 0.2,
      // type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
}

export const motionNavigationSmallButtonVariants = {
  initial: {
    borderRadius: 40,
  },
  hover: {
    borderRadius: 12,
    transition: { delay: 0 },
  },
  tap: {
    borderRadius: 12,
    transition: { delay: 0 },
  },
}

export const motionOverlayVariants = {
  hidden: { x: '100vw' },
  visible: { x: 0 },
  exit: { x: '100vw' },
}

export const motionOverlayTransition = {
  type: 'spring',
  duration: 0.9,
  bounce: 0,
}

export const motionTagsSticky = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

export const motionSearch = {
  hidden: { scale: 0, opacity: 0.5 },
  visible: { scale: 1, opacity: 1 },
  exit: { scale: 0, opacity: 0.5 },
}

export const motionSearchHeader = {
  initial: { height: 'auto', opacity: 1 },
  hidden: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
}

export const motionSearchInput = {
  initial: { right: 0 },
  focus: { right: 130 },
}

export const motionSearchInputClear = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { delay: 0.4 } },
}

export const motionSearchResultSectionVariants = {
  initial: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
}

export const motionSearchResultItemVariants = {
  initial: {
    x: '100vw',
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: '100vw',
    opacity: 0,
  },
}

export const motionSearchResultItemEventDetailVariants = {
  initial: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
}

export const motionShareVariants = {
  initial: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0.3,
    transition: {
      duration: 0.2,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
}

export const motionShareButtonVariants = {
  initial: {
    borderRadius: 40,
  },
  hover: {
    borderRadius: 12,
    transition: { delay: 0 },
  },
  tap: {
    borderRadius: 12,
    transition: { delay: 0 },
  },
}
