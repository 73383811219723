import React, { ReactElement } from 'react'
import classnames from 'classnames'

import './PreviewEmpty.scss'

type PreviewEmptyProps = {
  className?: string
}

const PreviewEmpty = ({ className }: PreviewEmptyProps): ReactElement => (
  <div className={classnames('PreviewEmpty', className)}>
    <div className="PreviewEmpty__ratio"></div>
  </div>
)

export default PreviewEmpty
