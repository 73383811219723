import React, { ReactElement, useState } from 'react'
import Clipboard from 'react-clipboard.js'
import encodeurl from 'encodeurl'
import { motion } from 'framer-motion'

import {
  motionButtonVariants,
  motionShareButtonVariants,
  motionShareVariants,
} from 'utils/motionVariants'

import IconCheck from 'view/components/icons/IconCheck'
import IconClose from 'view/components/icons/IconClose'

import './Share.scss'

type ShareProps = {
  title: string
  url: string
  tags: string
  actionUIToggle: (type: string, open: boolean) => void
}

const Share = ({
  title,
  tags,
  url,
  actionUIToggle,
}: ShareProps): ReactElement => {
  const [copyDone, setCopyDone] = useState(false)
  const [timer, setTimer] = useState<any>(null)

  const closeShareDialogClick = () => actionUIToggle('share', false)

  const onCopySuccess = () => {
    setCopyDone(true)
    setTimer(window.setTimeout(() => setCopyDone(false), 1600))
  }

  const handleFacebookClick = () => {
    const popupWidth = 626
    const popupHeight = 436

    // center popup
    // Fixes dual-screen position Most browsers Firefox
    const dualScreenLeft =
      window.screenLeft !== undefined
        ? window.screenLeft
        : (window as any).screen.left
    const dualScreenTop =
      window.screenTop !== undefined
        ? window.screenTop
        : (window as any).screen.top
    const winWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width
    const winHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

    const popupTop = winHeight / 2 - popupHeight / 2 + dualScreenTop
    const popupLeft = winWidth / 2 - popupWidth / 2 + dualScreenLeft

    const newWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?display=popup&href=${url}&fallback_redirect_uri=${url}`,
      'facebook-share-dialog',
      `toolbar=0, status=0, resizable=1, width=${popupWidth}, height=${popupHeight}, top=${popupTop}, left=${popupLeft}`
    )

    // Puts focus on the newWindow
    if ((window as any).focus) {
      ;(newWindow as any).focus()
    }
  }

  const textCopyLink = 'Link kopieren'
  const textCopyLinkDone = (
    <>
      <span className="Share__link__copy__check">
        <IconCheck />
      </span>
      <span className="Share__link__copy__txt">Link kopiert</span>
    </>
  )
  const textUrlEncoded = encodeurl(`${title}${tags ? ', ' + tags : ''}, ${url}`)

  return (
    <motion.div
      variants={motionShareVariants}
      className="Share"
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <button className="Share__outerclose" onClick={closeShareDialogClick} />

      <div className="Share__inner">
        <div className="Share__title">{title}</div>
        {tags && <div className="Share__tags">{tags}</div>}

        <div className="Share__links">
          <motion.div
            variants={motionShareButtonVariants}
            className="Share__link__copy"
            initial="initial"
            whileHover="hover"
            whileTap="tap"
          >
            <Clipboard
              data-clipboard-text={url}
              onSuccess={onCopySuccess}
              className="Share__link__copy__btn"
            >
              {copyDone ? textCopyLinkDone : textCopyLink}
            </Clipboard>
          </motion.div>

          <motion.div
            variants={motionShareButtonVariants}
            className="Share__link"
            initial="initial"
            whileHover="hover"
            whileTap="tap"
          >
            <a
              className="Share__link__anchor"
              href={`https://wa.me/?text=${textUrlEncoded}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              Per WhatsApp teilen
            </a>
          </motion.div>

          <motion.button
            variants={motionShareButtonVariants}
            className="Share__link__fb"
            initial="initial"
            whileHover="hover"
            whileTap="tap"
            onClick={handleFacebookClick}
          >
            <div className="Share__link__fb__inner">Per Facebook teilen</div>
          </motion.button>

          <motion.div
            variants={motionShareButtonVariants}
            className="Share__link"
            initial="initial"
            whileHover="hover"
            whileTap="tap"
          >
            <a
              className="Share__link__anchor"
              href={`mailto:?subject=TRIBEKA – ${title}&body=${textUrlEncoded}`}
            >
              Per Mail teilen
            </a>
          </motion.div>
        </div>

        <motion.button
          variants={motionButtonVariants}
          className="Share__close"
          whileHover="hover"
          whileTap="tap"
          onClick={closeShareDialogClick}
        >
          <IconClose />
        </motion.button>
      </div>
    </motion.div>
  )
}

export default Share
