import React, { ReactElement } from 'react'

import './Heading.scss'

type HeadingProps = {
  title: string
}

const Heading = ({ title }: HeadingProps): ReactElement => {
  return (
    <div className="Heading">
      <div className="Heading__inner">{title}</div>
    </div>
  )
}

export default Heading
