import { AnyAction } from 'redux'

import { UIStateType } from 'data/types'
import { UI_SELECT_TAG, UI_TOGGLE } from './types'

const initialState: UIStateType = {
  selectedTag: '',
  showDetail: false,
  showMaps: false,
  showNavigationSmall: false,
  showPage: false,
  showSearch: false,
  showShare: false,
}

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export default function (state = initialState, action: AnyAction): UIStateType {
  switch (action.type) {
    case UI_SELECT_TAG: {
      const { tag } = action.payload

      return {
        ...state,
        selectedTag: tag,
      }
    }

    case UI_TOGGLE: {
      const { type, open } = action.payload

      const newState = Object.assign({}, state)
      newState['show' + capitalizeFirstLetter(type)] = open

      return {
        ...newState,
      }
    }

    default:
      return state
  }
}
