import { HOME_GRID_MAX, MAPS_LIST_MAX } from 'Constants'

const homeGridMax = parseInt(HOME_GRID_MAX, 10)
const mapListMax = parseInt(MAPS_LIST_MAX, 10)

export const getHomeItems = (itemsOpen, itemsClosed) =>
  itemsOpen.length >= homeGridMax
    ? [...itemsOpen.slice(0, homeGridMax)]
    : [...itemsOpen, ...itemsClosed.slice(0, homeGridMax - itemsOpen.length)]

export const getMapsItems = (itemsOpen, itemsClosed) =>
  itemsOpen.length >= mapListMax
    ? [...itemsOpen.slice(0, mapListMax)]
    : [...itemsOpen, ...itemsClosed.slice(0, mapListMax - itemsOpen.length)]

export const getTags = (items, proximity) => {
  let tags = []

  items.map((item) => {
    const { open, tags: itemTags } = item

    itemTags.map((tag) => {
      const { id, slug, title } = tag
      const tagFind = tags.find((item) => item.id === id)

      if (tagFind) {
        open ? tagFind.itemsOpen.push(item) : tagFind.itemsClosed.push(item)
      } else {
        tags.push({
          id: id,
          slug: slug,
          title: title,
          itemsOpen: open ? [item] : [],
          itemsClosed: open ? [] : [item],
        })
      }
    })
  })

  tags.map((section) => {
    section.items = [...section.itemsOpen, ...section.itemsClosed]
    section.total = section.itemsOpen.length + section.itemsClosed.length
    section.minDistance = proximity
      ? Math.min(...section.itemsOpen.map((item) => item.distance))
      : null
  })

  if (proximity) {
    // tags with least min distance of open items first,
    // so index is sorted by min distance
    tags = tags.sort((a, b) => a.minDistance - b.minDistance)
  } else {
    // otherwise sort by the tag with most open items first,
    // so index is sorted by most open
    tags = tags.sort((a, b) => b.itemsOpen.length - a.itemsOpen.length)
  }

  return tags
}
