import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import {
  motion,
  useAnimation,
  useDragControls,
  useMotionValue,
} from 'framer-motion'

import { MemberType } from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import {
  membersDisabledUserInteractedWithMaps,
  membersUpdateMapsLocation,
} from 'data/redux/members/actions'
import { uiToggle } from 'data/redux/ui/actions'
import {
  motionButtonVariants,
  motionMap,
  motionMapResetButton,
  motionMapResetButtonMobile,
} from 'utils/motionVariants'
import IconClose from 'view/components/icons/IconClose'

import MapsViewer from './MapsViewer'
import MapsDetail from './MapsDetail'

import './Maps.scss'

const mapStateToProps = (state: RootState) => ({
  itemsOpen: state.members.itemsOpen,
  itemsClosed: state.members.itemsClosed,
  itemsMap: state.members.itemsMap,
  proximity: state.members.proximity,
  user: state.members.user,
  maps: state.members.maps,
  userInteractedWithMaps: state.members.userInteractedWithMaps,
  showMaps: state.ui.showMaps,
})

const mapDispatchToProps = {
  actionDisabledUserInteractedWithMaps: membersDisabledUserInteractedWithMaps,
  actionUpdateMapsLocation: membersUpdateMapsLocation,
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type MapsProps = ConnectedProps<typeof connector>

const Maps = ({
  itemsOpen,
  itemsClosed,
  itemsMap,
  proximity,
  user,
  maps,
  userInteractedWithMaps,
  showMaps,
  actionDisabledUserInteractedWithMaps,
  actionUpdateMapsLocation,
  actionUIToggle,
}: MapsProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const [detailPosition, setDetailPosition] = useState('closed')
  const [itemDetail, setItemDetail] = useState(null)
  const y = useMotionValue(0)
  const controls = useAnimation()
  const dragControls = useDragControls()
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  })

  const handleHeaderClick = () => actionUIToggle('maps', !showMaps)
  const handleHeaderPointerDown = (ev) => dragControls.start(ev)
  const handleDragEnd = () => {
    if (y.get() > 20) {
      actionUIToggle('maps', false)
    } else if (y.get() < -20) {
      actionUIToggle('maps', true)
    }
  }

  const handleListOpen = () => {
    setItemDetail(null)
    setDetailPosition('list')
  }

  const handleListClose = () => {
    setItemDetail(null)
    setDetailPosition('closed')
  }

  const handleMapClick = () => {
    setItemDetail(null)
    if (detailPosition === 'item') {
      setDetailPosition('closed')
    }
  }

  const handleMapItemClick = (item: MemberType) => {
    setItemDetail(item)
    setDetailPosition('item')
  }

  const handleMapResetButton = () => {
    actionDisabledUserInteractedWithMaps()
    setItemDetail(null)

    if (detailPosition === 'item') {
      setDetailPosition('closed')
    }
  }

  // animate on state showMaps
  useEffect(() => {
    if (showMaps) {
      controls.start('visible')
    } else if (!showMaps) {
      controls.start('hidden')
    }
  }, [controls, showMaps])

  useEffect(() => {
    if (itemDetail !== null) {
      actionUpdateMapsLocation(itemDetail.lat, itemDetail.lng)
    }
  }, [itemDetail, actionUpdateMapsLocation])

  // handle body scroll lock (with iOS15 hack)
  const preventDefault = (ev) => ev.preventDefault()

  const onAnimationStart = (definition: string) => {
    if (definition === 'hidden') {
      if (ref && ref.current) {
        ref.current.removeEventListener('pointermove', preventDefault)
        ref.current.removeEventListener('touchmove', preventDefault)
      }
    }
  }

  const onAnimationComplete = (definition: string) => {
    if (definition === 'visible') {
      if (ref && ref.current) {
        ref.current.addEventListener('pointermove', preventDefault)
        ref.current.addEventListener('touchmove', preventDefault)
      }
    }
  }

  const variantsMapResetButton = isMobile
    ? motionMapResetButtonMobile
    : motionMapResetButton

  return (
    <motion.aside
      className="Maps"
      initial="hidden"
      animate={controls}
      transition={{
        type: 'spring',
        damping: 40,
        stiffness: 400,
      }}
      variants={motionMap}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragControls={dragControls}
      dragListener={false}
      onDragEnd={handleDragEnd}
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
      style={{ y }}
      ref={ref}
    >
      <div
        className="Maps__header"
        onClick={handleHeaderClick}
        onPointerDown={handleHeaderPointerDown}
      >
        <div className="Maps__header__bg" />
        <div className="Maps__header__title">Karte</div>
        {showMaps && (
          <motion.button
            variants={motionButtonVariants}
            className="Maps__header__close"
            whileHover="hover"
            whileTap="tap"
          >
            <IconClose />
          </motion.button>
        )}
      </div>
      <div className="Maps__content">
        <div className="Maps__content__maps">
          <MapsViewer
            itemsOpen={itemsOpen}
            itemsClosed={itemsClosed}
            itemDetail={itemDetail}
            user={user}
            maps={maps}
            proximity={proximity}
            handleMapClick={handleMapClick}
            handleMapItemClick={handleMapItemClick}
            actionUpdateMapsLocation={actionUpdateMapsLocation}
          />
        </div>

        <MapsDetail
          position={detailPosition}
          itemsMap={itemsMap}
          itemDetail={itemDetail}
          proximity={proximity}
          handleListClose={handleListClose}
          handleListOpen={handleListOpen}
          handleMapItemClick={handleMapItemClick}
        />

        {userInteractedWithMaps && (
          <motion.button
            initial="closed"
            animate={detailPosition}
            transition={{
              type: 'spring',
              damping: 40,
              stiffness: 400,
            }}
            variants={variantsMapResetButton}
            className="Maps__reset__button"
            onClick={handleMapResetButton}
          >
            Zum Standort
          </motion.button>
        )}
      </div>
    </motion.aside>
  )
}

export default connector(Maps)
