import React, { ReactElement, RefObject, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'
import { hasBodyClass, hasHtmlClass } from 'utils/bodyClass'
import {
  motionHeaderBackdrop,
  motionHeaderBg,
  motionHeaderClaim,
} from 'utils/motionVariants'
import { posTop } from 'utils/positioning'
import { useIsomorphicLayoutEffect } from 'utils/useIsomorphicLayoutEffect'
import IconMenu from 'view/components/icons/IconMenu'
import IconSearch from 'view/components/icons/IconSearch'
import Navigation from 'view/components//navigation/Navigation'
import NavigationSmall from 'view/components/navigation-small/NavigationSmall'

import './Header.scss'

const mapStateToProps = (state: RootState) => ({
  showNavigationSmall: state.ui.showNavigationSmall,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface HeaderProps extends PropsFromRedux {
  refBarrier?: RefObject<HTMLDivElement>
  scrollAnimation: boolean
}

const Header = ({
  showNavigationSmall,
  refBarrier,
  scrollAnimation,
  actionUIToggle,
}: HeaderProps): ReactElement => {
  const [isSticky, setSticky] = useState(!scrollAnimation)

  const handleNavigationSmallOpen = () =>
    actionUIToggle('navigationSmall', true)
  const handleSearchOpen = () => actionUIToggle('search', true)

  const onScroll = () => {
    const barrierTop = posTop(refBarrier.current)
    const limitTop = hasBodyClass('has-cookie-banner') ? 22 : 0

    setSticky(barrierTop < limitTop)
  }

  useIsomorphicLayoutEffect(() => {
    if (scrollAnimation) {
      window.addEventListener('scroll', onScroll)

      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [refBarrier, scrollAnimation])

  useEffect(() => {
    if (scrollAnimation) {
      onScroll()
    }
  }, [scrollAnimation])

  const classes = classnames('Header', {
    'Header--sticky': isSticky,
  })

  return (
    <>
      <header className={classes}>
        <div className="Header__wrapper">
          <motion.div
            variants={motionHeaderBg}
            className="Header__bg"
            initial="visible"
            animate={isSticky ? 'hidden' : 'visible'}
          ></motion.div>
          <motion.div
            variants={motionHeaderBackdrop}
            className="Header__backdrop"
            initial="hidden"
            animate={isSticky ? 'visible' : 'hidden'}
          ></motion.div>

          <div className="Header__inner">
            <button
              className="Header__menu"
              onClick={handleNavigationSmallOpen}
            >
              <IconMenu />
            </button>

            <div className="Header__logo">
              <Link className="Header__logo__link" to="/">
                Tribeka
              </Link>
            </div>

            <motion.div className="Header__navigation">
              <Navigation />
            </motion.div>

            <button className="Header__search" onClick={handleSearchOpen}>
              <IconSearch />
            </button>

            {scrollAnimation && (
              <motion.div
                variants={motionHeaderClaim}
                className="Header__claim"
                initial="visible"
                animate={isSticky ? 'hidden' : 'visible'}
              >
                All You Need. Right Here.
              </motion.div>
            )}
          </div>
        </div>
      </header>
      {scrollAnimation && (
        <div className="Header__claim__small">All You Need. Right Here.</div>
      )}
      <AnimatePresence>
        {showNavigationSmall && <NavigationSmall />}
      </AnimatePresence>
    </>
  )
}

export default connector(Header)
