import { useApolloClient } from '@apollo/client'
import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect, ConnectedProps } from 'react-redux'
import classnames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { debounce } from 'throttle-debounce'

import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'
import {
  motionButtonMapVariants,
  motionSearch,
  motionSearchHeader,
  motionSearchInput,
  motionSearchInputClear,
  motionSearchResultSectionVariants,
} from 'utils/motionVariants'
import { SEARCH_QUERY } from 'data/queries/search'
import IconClose from 'view/components/icons/IconClose'
import IconSearch from 'view/components/icons/IconSearch'

import SearchResultItemPage from './SearchResultsItemPage'
import SearchResultsItemEvent from './SearchResultsItemEvent'
import SearchResultsItemMember from './SearchResultsItemMember'

import './Search.scss'

const mapStateToProps = (state: RootState) => ({
  events: state.events.items,
  members: state.members.items,
  pages: state.pages.items,
  proximity: state.members.proximity,
  showSearch: state.ui.showSearch,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type SearchProps = ConnectedProps<typeof connector>

const Search = ({
  events,
  members,
  pages,
  proximity,
  showSearch,
  actionUIToggle,
}: SearchProps): ReactElement => {
  const client = useApolloClient()
  const inputRef = useRef<HTMLInputElement>(null)
  const [results, setResults] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [inFocus, setInFocus] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  // @NOTE: search callback, once a state variable is changed
  // we use a debounced version via useRef and with debounce() function
  const queryBackend = async (clientRaw: any, searchValueRaw: string) => {
    if (searchValueRaw) {
      const { data } = await clientRaw.query({
        query: SEARCH_QUERY,
        variables: {
          search: searchValueRaw,
        },
      })

      if (data.results) {
        setShowResults(true)
        setResults(data.results)
      } else {
        setShowResults(true)
        setResults([])
      }
    } else {
      setShowResults(false)
      setResults([])
    }
  }

  const queryBackendDebounced = useRef(
    debounce(500, (clientRaw: any, searchValueRaw: string) =>
      queryBackend(clientRaw, searchValueRaw)
    )
  )

  useEffect(() => {
    queryBackendDebounced.current(client, searchValue)
  }, [client, searchValue])

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      setInFocus(true)

      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }

    window.addEventListener('keydown', keyDownHandler)

    return () => window.removeEventListener('keydown', keyDownHandler)
  }, [inputRef, setInFocus])

  const clearSearch = () => setSearchValue('')
  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(ev.target.value)
  const onInputFocus = () => setInFocus(true)
  const onInputBlur = () => setInFocus(false)
  const closeSearchClick = () => actionUIToggle('search', false)

  let resultsSections = null

  if (results && results.length > 0) {
    const resultsEvents = []
    const resultsMembers = []
    const resultsPages = []

    results.map((item, index) => {
      const { id, type } = item

      if (type === 'events') {
        const event = events.find((event) => event.id === id)

        if (event) {
          const member = members.find(
            (member) => member.author === event.author
          )

          resultsEvents.push(
            <SearchResultsItemEvent key={id} member={member} {...event} />
          )
        }
      } else if (type === 'members') {
        const member = members.find((member) => member.id === id)

        if (member) {
          resultsMembers.push(
            <SearchResultsItemMember
              key={id}
              proximity={proximity}
              {...member}
            />
          )
        }
      } else if (type === 'pages') {
        const page = pages.find((page) => page.id === id)

        if (page) {
          resultsPages.push(<SearchResultItemPage key={id} {...page} />)
        }
      }
    })

    resultsSections = (
      <>
        {resultsEvents.length > 0 && (
          <motion.div
            variants={motionSearchResultSectionVariants}
            initial="initial"
            animate="visible"
            exit="exit"
            className="Search__results__section"
          >
            <div className="Search__results__section__title">Events</div>
            <div className="Search__results__section__items">
              {resultsEvents}
            </div>
          </motion.div>
        )}
        {resultsMembers.length > 0 && (
          <motion.div
            variants={motionSearchResultSectionVariants}
            className="Search__results__section"
            initial="initial"
            animate="visible"
          >
            <div className="Search__results__section__title">Mitglieder</div>
            <div className="Search__results__section__items">
              {resultsMembers}
            </div>
          </motion.div>
        )}
        {resultsPages.length > 0 && (
          <motion.div
            variants={motionSearchResultSectionVariants}
            className="Search__results__section"
            initial="initial"
            animate="visible"
            exit="exit"
          >
            <div className="Search__results__section__title">Seiten</div>
            <div className="Search__results__section__items">
              {resultsPages}
            </div>
          </motion.div>
        )}
      </>
    )
  }

  const classes = classnames('Search', {
    'Search--focus': inFocus || searchValue !== '',
  })

  return (
    <AnimatePresence>
      {showSearch && (
        <motion.div
          variants={motionSearch}
          className={classes}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.button
            variants={motionButtonMapVariants}
            className="Search__close"
            initial="hidden"
            animate="visible"
            exit="hidden"
            whileHover="hover"
            whileTap="tap"
            onClick={closeSearchClick}
          >
            <IconClose />
          </motion.button>
          <div className="Search__inner">
            <motion.div
              variants={motionSearchHeader}
              className="Search__header"
              initial="initial"
              animate={inFocus || searchValue !== '' ? 'hidden' : 'visible'}
            >
              <div className="Search__header__inner">Suche</div>
            </motion.div>

            <div className="Search__input">
              <motion.div
                variants={motionSearchInput}
                className="Search__input__inner"
                initial="initial"
                animate={inFocus || searchValue !== '' ? 'focus' : 'initial'}
              >
                <div className="Search__input__icon">
                  <IconSearch />
                </div>

                <input
                  className="Search__input__field"
                  type="text"
                  onChange={onInputChange}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  value={searchValue}
                  ref={inputRef}
                />

                <motion.button
                  onClick={clearSearch}
                  variants={motionSearchInputClear}
                  className="Search__input__clear"
                  initial="hidden"
                  animate={inFocus || searchValue !== '' ? 'visible' : 'hidden'}
                >
                  Abbrechen
                </motion.button>
              </motion.div>
            </div>

            {showResults && resultsSections && (
              <div className="Search__results">{resultsSections}</div>
            )}
            {showResults && !resultsSections && (
              <div className="Search__results__none">
                Keine Ergebnisse gefunden.
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default connector(Search)
