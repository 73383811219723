import React, { FC } from 'react'

import './DragCursor.scss'

type Props = {
  coords?: [number, number]
}

const DragCursor: FC<Props> = ({ coords }: Props) => {
  if (!coords) {
    return null
  }

  const style = {
    transform: `translate(${coords[0] - 10}px, ${coords[1] - 10}px)`,
  }

  return (
    <div className="DragCursor" style={style}>
      <div className="DragCursor__inner">Drag</div>
    </div>
  )
}

export default DragCursor
