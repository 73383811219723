import { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'

const mapStateToProps = (state: RootState) => ({
  showDetail: state.ui.showDetail,
  showMaps: state.ui.showMaps,
  showNavigationSmall: state.ui.showNavigationSmall,
  showPage: state.ui.showPage,
  showSearch: state.ui.showSearch,
  showShare: state.ui.showShare,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ScrollBlockProps = ConnectedProps<typeof connector>

const ScrollBlock = ({
  showDetail,
  showMaps,
  showNavigationSmall,
  showPage,
  showSearch,
  showShare,
  actionUIToggle,
}: ScrollBlockProps): null => {
  const location = useLocation()
  const navigate = useNavigate()
  const [scrollY, setScrollY] = useState(0)
  const [isBlocked, setIsBlocked] = useState(false)

  const state = location.state as { backgroundLocation?: Location }

  // ESC key handler, depending on which overlay is open
  useEffect(() => {
    const navigateBackOrHome = () => {
      if (state?.backgroundLocation) {
        navigate(-1)
      } else {
        navigate('/')
      }
    }

    const onEscKeyPress = (ev) => {
      if (ev.key === 'Escape' || ev.key === 'Esc') {
        // close overlay depending on order:
        // 1. share
        // 2. page | detail
        // 3. map | search
        // 4. navigationSmall
        // 5. do nothing
        if (showShare) {
          actionUIToggle('share', false)
        } else if (showPage) {
          actionUIToggle('page', false)
          navigateBackOrHome()
        } else if (showDetail) {
          actionUIToggle('detail', false)
          navigateBackOrHome()
        } else if (showMaps) {
          actionUIToggle('maps', false)
        } else if (showSearch) {
          actionUIToggle('search', false)
        } else if (showNavigationSmall) {
          actionUIToggle('navigationSmall', false)
        }
      }
    }

    window.addEventListener('keyup', onEscKeyPress)
    return () => window.removeEventListener('keyup', onEscKeyPress)
  }, [
    showDetail,
    showMaps,
    showNavigationSmall,
    showPage,
    showSearch,
    showShare,
    state,
  ])

  // iOS 15 hack for bottom address bar
  useEffect(() => {
    const updateHeight = () => {
      document.documentElement.style.setProperty(
        '--window-inner-height',
        `${window.innerHeight}px`
      )
    }

    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  })

  // add class if overlay is present
  useEffect(() => {
    const inOverlay = showDetail || showMaps || showSearch || showPage

    if (inOverlay && !isBlocked) {
      setIsBlocked(true)
      setScrollY(window.scrollY)
      document.documentElement.classList.add('is-locked')
    } else if (!inOverlay && isBlocked) {
      setIsBlocked(false)
      document.documentElement.classList.remove('is-locked')
      window.scrollTo(0, scrollY)
    }
  }, [showDetail, showMaps, showPage, showSearch, isBlocked, setIsBlocked])

  return null
}

export default connector(ScrollBlock)
