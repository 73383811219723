import React, {
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper'
import { useMediaQuery } from 'react-responsive'
import {
  motion,
  useAnimation,
  useDragControls,
  useMotionValue,
} from 'framer-motion'

import { MemberType } from 'data/types'
import { motionMapDetail, motionMapDetailMobile } from 'utils/motionVariants'
import DragCursor from 'view/components/drag-cursor/DragCursor'
import IconArrowRight from 'view/components/icons/IconArrowRight'
import Image from 'view/components/image/Image'
import Preview from 'view/components/preview/Preview'

import './MapsDetail.scss'

type MapsDetailProps = {
  position: string
  itemsMap: MemberType[]
  itemDetail: MemberType | null
  proximity: boolean
  handleListClose: () => void
  handleListOpen: () => void
  handleMapItemClick: (item: MemberType) => void
}

const MapsDetail = ({
  position,
  itemsMap,
  itemDetail,
  proximity,
  handleListClose,
  handleListOpen,
  handleMapItemClick,
}: MapsDetailProps): ReactElement => {
  const location = useLocation()
  const ref = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0])
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  })
  const y = useMotionValue(0)
  const controls = useAnimation()
  const dragControls = useDragControls()

  const onMouseOver = () => setHover(true)
  const onMouseLeave = () => setHover(false)
  const onMouseMove = (ev: MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      const mouseCoords: [number, number] = [
        ev.clientX - bounds.left,
        ev.clientY - bounds.top,
      ]

      setMouseCoords(mouseCoords)
    }
  }
  const onFocus = () => void 0

  const handleListClick = () => {
    if (position === 'list') {
      handleListClose()
    } else if (position === 'closed') {
      handleListOpen()
    }
  }
  const handleListPointerDown = (ev) => dragControls.start(ev)
  const handleDragEnd = () => {
    if (y.get() > 30) {
      handleListClose()
    } else if (y.get() < -50) {
      handleListOpen()
    }
  }

  useEffect(() => {
    controls.start(position)
  }, [controls, position, isMobile])

  const swiperParams: ReactIdSwiperProps = {
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoHeight: true,
  }

  let hint = proximity ? 'In deiner Nähe ↓' : 'Auswahl'
  const sliderItems = itemsMap.map((item) => {
    const onItemClick = () => handleMapItemClick(item)

    return (
      <Preview
        key={item.id}
        proximity={proximity}
        clickAction={onItemClick}
        {...item}
      />
    )
  })

  const list = (position === 'closed' || position === 'list') && (
    <div className="MapsDetail__list">
      <div
        className="MapsDetail__list__hint"
        onClick={handleListClick}
        onPointerDown={handleListPointerDown}
      >
        {hint}
      </div>
      <div
        className="MapsDetail__list__slideshow"
        ref={ref}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onFocus={onFocus}
      >
        <div className="MapsDetail__list__slideshow__inner">
          <Swiper {...swiperParams}>{sliderItems}</Swiper>
          {hover && <DragCursor coords={mouseCoords} />}
        </div>
      </div>
    </div>
  )

  let detail = null

  if (position === 'item' && itemDetail) {
    const { slug, title, thumbnail, tags, open, distance } = itemDetail

    const tagsText = tags.map((item) => item.title).join(', ')

    const img = thumbnail && thumbnail.length > 0 && (
      <figure className="MapsDetail__item__figure">
        <Image image={thumbnail[0]} className="MapsDetail__item__figure__img" />
      </figure>
    )

    let distanceText = null
    if (distance && proximity) {
      distanceText = `→ ${Math.round(distance)}M`
    }

    const svgCircle = (
      <svg version="1.1" viewBox="0 0 12 12">
        <circle cx="6" cy="6" r="6" />
      </svg>
    )

    const availablity =
      open === null ? null : open ? (
        <>
          <span className="MapsDetail__item__availability__open">
            {svgCircle}
          </span>
          <span className="MapsDetail__item__availability__text">Open</span>
        </>
      ) : (
        <>
          <span className="MapsDetail__item__availability__closed">
            {svgCircle}
          </span>
          <span className="MapsDetail__item__availability__text">Closed</span>
        </>
      )

    detail = (
      <div className="MapsDetail__item">
        <Link
          className="MapsDetail__item__link"
          to={`/detail/${slug}`}
          state={{ backgroundLocation: location }}
        >
          <div className="MapsDetail__item__inner">
            <div className="MapsDetail__item__left">{img}</div>
            <div className="MapsDetail__item__right">
              <div className="MapsDetail__item__title">{title}</div>
              <div className="MapsDetail__item__tags">{tagsText}</div>
              {distanceText && (
                <div className="MapsDetail__item__distance">{distanceText}</div>
              )}
              {availablity && (
                <div className="MapsDetail__item__availability">
                  {availablity}
                </div>
              )}
            </div>
            <div className="MapsDetail__item__button">
              <IconArrowRight />
            </div>
          </div>
        </Link>
      </div>
    )
  }

  const variants = isMobile ? motionMapDetailMobile : motionMapDetail

  return (
    <motion.div
      className="MapsDetail"
      initial="closed"
      animate={controls}
      transition={{
        type: 'spring',
        damping: 40,
        stiffness: 400,
      }}
      variants={variants}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      dragControls={dragControls}
      dragListener={false}
      onDragEnd={handleDragEnd}
      style={{ y }}
    >
      {list}
      {detail}
    </motion.div>
  )
}

export default MapsDetail
