import { AnyAction } from 'redux'

import { NavigationStateType } from 'data/types'
import { NAVIGATION_DATA_LOAD } from './types'

const initialState: NavigationStateType = {
  items: [],
}

export default function (
  state = initialState,
  action: AnyAction
): NavigationStateType {
  switch (action.type) {
    case NAVIGATION_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        return {
          ...state,
          items: data,
        }
      }
    }

    default:
      return state
  }
}
