import React, { MouseEvent, ReactElement, useRef, useState } from 'react'
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper'

import DragCursor from 'view/components/drag-cursor/DragCursor'
import Preview from 'view/components/preview/Preview'

type IndexSlideshowProps = {
  items: any[]
  proximity: boolean
}

const IndexSlideshow = ({
  items,
  proximity,
}: IndexSlideshowProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0])

  const onMouseOver = () => setHover(true)
  const onMouseLeave = () => setHover(false)
  const onMouseMove = (ev: MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      const mouseCoords: [number, number] = [
        ev.clientX - bounds.left,
        ev.clientY - bounds.top,
      ]

      setMouseCoords(mouseCoords)
    }
  }
  const onFocus = () => void 0

  const sliderItems = items.map((item) => (
    <Preview key={item.id} proximity={proximity} {...item} />
  ))

  const swiperParams: ReactIdSwiperProps = {
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoHeight: true,
  }

  return (
    <div
      className="Index__section__slideshow"
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onFocus={onFocus}
    >
      <div className="Index__section__slideshow__inner">
        <Swiper {...swiperParams}>{sliderItems}</Swiper>
        {hover && <DragCursor coords={mouseCoords} />}
      </div>
    </div>
  )
}

export default IndexSlideshow
