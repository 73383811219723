import React, { ReactElement } from 'react'

import './Icons.scss'

const IconArrowRight = (): ReactElement => (
  <svg className="IconArrowRight" viewBox="0 0 10 19.2">
    <polygon points="0,0 0,19.2 10,9.6 " />
  </svg>
)

export default IconArrowRight
