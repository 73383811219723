import { decode } from 'he'
import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import striptags from 'striptags'
import truncate from 'truncate'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'

const combineMetaData = (defaultMetaData: any, pageMetaData: any) => {
  const title =
    (pageMetaData.title &&
      `${pageMetaData.title} – ${
        defaultMetaData.title && defaultMetaData.title
      }`) ||
    (defaultMetaData.title && defaultMetaData.title)

  // compose
  const metaData = { ...defaultMetaData, ...pageMetaData, title }

  // adjustemnts to image
  if (
    pageMetaData.images &&
    typeof pageMetaData.images === 'object' &&
    pageMetaData.images.length > 0
  ) {
    // find an image with good quality
    const find =
      pageMetaData.images.find((image: any) => image.width > 800) ||
      (metaData.images && metaData.images[0])

    // if URL doesn't exist, fallback on default image
    metaData.image = [find] || metaData.image
  }

  // adjustments to the url
  if (pageMetaData.url) {
    if (pageMetaData.url.match(/^http/)) {
      // 1. absolute url used as-is
      metaData.url = pageMetaData.url
    } else if (pageMetaData.url.match(/^\//)) {
      // 2. prefix base url for absolute url without https://
      metaData.url = `${BASE_URL}/${pageMetaData.url}`
    } else {
      // 3. prefix relative urls with an extra /
      metaData.url = `${BASE_URL}/${pageMetaData.url}`
    }
  }

  return metaData
}

const getTitle = (title: any) =>
  (typeof title === 'string' &&
    decode(striptags(title, ['br']).replace(/<br\s*\/?>/gi, ' '))) ||
  ''

// truncate description after 350 chars
const getDescription = (description: any) =>
  truncate(
    (typeof description === 'string' &&
      decode(striptags(description, ['br']).replace(/<br\s*\/?>/gi, ' '))) ||
      '',
    350,
    { ellipsis: typeof description === 'string' ? '…' : '' }
  )

const mapStateToProps = (state: RootState) => ({
  defaultMeta: state.meta,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
type DefaultHelmetProps = PropsFromRedux & {
  // fix for rest props
  [x: string]: any
}

const DefaultHelmet = ({
  defaultMeta,
  ...pageMetaData
}: DefaultHelmetProps): ReactElement => {
  const metaData = combineMetaData(defaultMeta, pageMetaData)
  const title = getTitle(metaData.title)
  const description = getDescription(metaData.description)
  const image = metaData.image.length > 0 ? metaData.image[0] : null

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {metaData.url && <meta property="og:url" content={metaData.url} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image.url} />}
    </Helmet>
  )
}

export default connector(DefaultHelmet)
