import React, { Fragment, ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { motion, AnimatePresence } from 'framer-motion'

import { BASE_URL } from 'Constants'
import { MemberType } from 'data/types'
import {
  motionOverlayVariants,
  motionOverlayTransition,
} from 'utils/motionVariants'

import DetailNavigation from 'view/components/detail-navigation/DetailNavigation'
import Heading from 'view/components/heading/Heading'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Image from 'view/components/image/Image'
import Meta from 'view/components/meta/Meta'
import Share from 'view/components/share/Share'
import Slideshow from 'view/components/slideshow/Slideshow'
import Text from 'view/components/text/Text'

import './Detail.scss'

type DetailProps = {
  slug: string
  member: MemberType
  proximity: boolean
  showShare: boolean
  actionUIToggle: (type: string, open: boolean) => void
}

const Detail = ({
  slug,
  member,
  proximity,
  showShare,
  actionUIToggle,
}: DetailProps): ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()

  const state = location.state as { backgroundLocation?: Location }
  const {
    title,
    tags,
    distance,
    open,
    thumbnail,
    contents,
    address,
    phone,
    email,
    website,
    facebook,
    instagram,
    openingHoursText,
    special,
  } = member

  const actionBackButton = () => {
    // navigate back or home
    if (state?.backgroundLocation) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }

  // 1. tags text
  const tagsText = tags.map((item) => item.title).join(', ')

  // 2. distance text
  let distanceText = null
  if (distance && proximity) {
    distanceText = `→ ${Math.round(distance)}M`
  }

  // 3. availablity
  const svgCircle = (
    <svg version="1.1" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="6" />
    </svg>
  )

  const availablity =
    open === null ? null : open ? (
      <>
        <span className="Detail__intro__availability__open">{svgCircle}</span>
        <span className="Detail__intro__availability__text">Open</span>
      </>
    ) : (
      <>
        <span className="Detail__intro__availability__closed">{svgCircle}</span>
        <span className="Detail__intro__availability__text">Closed</span>
      </>
    )

  // 4. intro img
  const introImg = thumbnail && thumbnail[0] && (
    <div className="Detail__intro">
      <div className="Detail__intro__ratio"></div>
      <Image image={thumbnail[0]} className="Detail__intro__img" />

      <div className="Detail__intro__tags">{tagsText}</div>

      {distanceText && (
        <div className="Detail__intro__distance">{distanceText}</div>
      )}

      {availablity && (
        <div className="Detail__intro__availability">{availablity}</div>
      )}
    </div>
  )

  // 5. meta sections
  let metaContact = null
  let metaOpening = null
  let metaSpecial = null

  if (address || phone || email || website || facebook || instagram) {
    const metaContactText = []
    address && metaContactText.push(address)
    phone && metaContactText.push(phone)
    email && metaContactText.push(`<a href="mailto:${email}">${email}</a>`)
    website &&
      metaContactText.push(
        `<a href="${website}" target="_blank" rel="noopener noreferrer">${website
          .replace(/(^\w+:|^)\/\//, '')
          .replace(/\/$/g, '')}</a>`
      )
    instagram &&
      metaContactText.push(
        `<a href="${instagram}" target="_blank" rel="noopener noreferrer">Instagram</a>`
      )
    facebook &&
      metaContactText.push(
        `<a href="${facebook}" target="_blank" rel="noopener noreferrer">Facebook</a>`
      )

    metaContact = <Meta content={metaContactText.join(', ')} />
  }

  if (openingHoursText) {
    const metaOpeningText = openingHoursText.replace(
      /(?:\r\n|\r|\n)/g,
      '<br />'
    )

    metaOpening = <Meta content={`Öffnungszeiten<br>${metaOpeningText}`} />
  }

  if (special) {
    const metaSpecialText = special.replace(/(?:\r\n|\r|\n)/g, '<br />')

    metaSpecial = (
      <Meta content={`Special<br />${metaSpecialText}`} special={true} />
    )
  }

  // 6. content blocks
  let helmetDescription = ''
  const blocks =
    contents &&
    contents.map((item: any) => {
      const { id, type, hidden } = item

      if (hidden) {
        return null
      }

      if (type === 'membersContents_text_BlockType') {
        if (helmetDescription === '') {
          helmetDescription = item.content
        }

        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={item.indent} />
            </Fragment>
          )
        } else {
          return <Text key={id} content={item.content} indent={item.indent} />
        }
      } else if (type === 'membersContents_textSmall_BlockType') {
        if (helmetDescription === '') {
          helmetDescription = item.content
        }

        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={false} size="tiny" />
            </Fragment>
          )
        } else {
          return (
            <Text key={id} content={item.content} indent={false} size="tiny" />
          )
        }
      } else if (
        type === 'membersContents_image_BlockType' &&
        item.image.length > 0
      ) {
        return (
          <figure key={id} className="Detail__contents__figure">
            <div
              className="Detail__contents__figure__ratio"
              style={{
                paddingTop: `${
                  (item.image[0].height / item.image[0].width) * 100
                }%`,
              }}
            ></div>
            <Image
              image={item.image[0]}
              className="Detail__contents__figure__img"
            />
          </figure>
        )
      } else if (type === 'membersContents_slideshow_BlockType') {
        return <Slideshow key={id} images={item.images} />
      }

      return null
    })

  const helmetParams = {
    title,
    description: helmetDescription,
    url: `${BASE_URL}/detail/${slug}`,
    images: thumbnail,
  }

  // handle body scroll lock (with iOS15 hack)
  const onAnimationStart = (definition: string) => {
    if (definition === 'exit') {
      actionUIToggle('detail', false)
    }
  }

  const onAnimationComplete = (definition: string) => {
    if (definition === 'visible') {
      actionUIToggle('detail', true)
    }
  }

  return (
    <>
      <Helmet {...helmetParams} />

      <motion.section
        className="Detail"
        variants={motionOverlayVariants}
        transition={motionOverlayTransition}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
      >
        <div className="Detail__inner">
          {introImg}

          <div className="Detail__title">{title}</div>

          <div className="Detail__contents">
            {metaContact}
            {metaOpening}
            {metaSpecial}
            {blocks}
          </div>
        </div>
      </motion.section>

      <DetailNavigation
        actionBackButton={actionBackButton}
        actionUIToggle={actionUIToggle}
      />

      <AnimatePresence>
        {showShare && (
          <Share
            actionUIToggle={actionUIToggle}
            title={title}
            tags={tagsText}
            url={`${BASE_URL}/detail/${slug}`}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Detail
