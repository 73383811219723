import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router'

import { CalendarEventParamsType } from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import Header from 'view/components/header/Header'
import NotFound from 'view/content/not-found/NotFound'
import CalendarSection from './CalendarSection'

import './Calendar.scss'

const mapStateToProps = (state: RootState) => ({
  sections: state.events.sections,
  members: state.members.items,
})

const connector = connect(mapStateToProps)

type CalendarEventProps = ConnectedProps<typeof connector>

const CalendarEvent = ({
  sections,
  members,
}: CalendarEventProps): ReactElement => {
  const { slug } = useParams<CalendarEventParamsType>()

  let foundEvent = false

  const section = Object.keys(sections).map((key) => {
    const subItems = sections[key]
    const findEvent = subItems.find((event) => event.slug === slug)

    if (findEvent) {
      foundEvent = true
      return (
        <CalendarSection
          key={key}
          title={key}
          items={[findEvent]}
          members={members}
        />
      )
    }

    return null
  })

  if (foundEvent) {
    return (
      <section className="Calendar">
        <Header scrollAnimation={false} />

        <div className="Calendar__inner">{section}</div>
      </section>
    )
  }

  return <NotFound />
}

export default connector(CalendarEvent)
