import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'

import './Navigation.scss'

const mapStateToProps = (state: RootState) => ({
  navigationItems: state.navigation.items,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type NavigationProps = ConnectedProps<typeof connector>

const Navigation = ({
  navigationItems,
  actionUIToggle,
}: NavigationProps): ReactElement => {
  const location = useLocation()
  const handleSearchOpen = () => actionUIToggle('search', true)

  const items = navigationItems.map((item) => {
    const { id, type, title } = item

    if (type === 'navigation_externalLink_Entry' && item.url) {
      return (
        <li className="Navigation__list__item" key={id}>
          <a
            className="Navigation__list__item__link"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        </li>
      )
    } else if (type === 'navigation_navigationItem_Entry') {
      const related = item.related.length > 0 && item.related[0]

      if (related) {
        return (
          <li className="Navigation__list__item" key={id}>
            <NavLink
              className={({ isActive }) =>
                `Navigation__list__item__link${
                  isActive ? ' Navigation__list__item__link--active' : ''
                }`
              }
              to={`/${related.slug}`}
              state={
                related.handle === 'page'
                  ? { backgroundLocation: location }
                  : null
              }
            >
              {title}
            </NavLink>
          </li>
        )
      }
    }

    return null
  })

  return (
    <nav className="Navigation">
      <ul className="Navigation__list">
        {items}
        <li className="Navigation__list__item">
          <button
            onClick={handleSearchOpen}
            className="Navigation__list__item__button"
          >
            Suche
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default connector(Navigation)
