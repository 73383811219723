import moment from 'moment-timezone'

import { TIMEZONE } from 'Constants'

export const isTimeBetween = (startTime, endTime, compareTime) => {
  const start = moment.tz(startTime, 'H:mm', TIMEZONE)
  const end = moment.tz(endTime, 'H:mm', TIMEZONE)
  const compare = moment.tz(compareTime, 'H:mm', TIMEZONE)

  if (end < start) {
    return (
      (compare >= start &&
        compare <= moment.tz('23:59:59', 'h:mm:ss', TIMEZONE)) ||
      (compare >= moment.tz('0:00:00', 'h:mm:ss', TIMEZONE) && compare < end)
    )
  }

  return compare >= start && compare < end
}

export const calculateOpenStore = (member) => {
  const now = moment().tz(TIMEZONE)
  const dayName = now.format('dddd').toLowerCase()
  const { closedFrom, closedUntil, openingHours } = member
  let isOpen = false

  openingHours.map((item) => {
    const { days, open, close } = item

    // check if the current days is included
    // if yes, check if now is between open and close
    if (days.includes(dayName) && isTimeBetween(open, close, now)) {
      isOpen = true
    }
  })

  // check if store is closed anyways (because of holidays, etc.)
  if (closedFrom && closedUntil) {
    const momentClosedFrom = moment.tz(closedFrom, TIMEZONE)
    const momentClosedUntil = moment.tz(closedUntil, TIMEZONE)

    if (now.isBetween(momentClosedFrom, momentClosedUntil)) {
      isOpen = false
    }
  }

  return isOpen
}
