import { gql } from '@apollo/client'

export const SEARCH_QUERY = gql`
  query SearchQuery($search: String) {
    results: entries(
      section: ["members", "events", "pages"]
      search: $search
      orderBy: "score"
    ) {
      id
      score: searchScore
      type: sectionHandle
    }
  }
`
