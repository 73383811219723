import React, { ReactElement, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment-timezone'

import { TIMEZONE } from 'Constants'
import { isTimeBetween } from 'utils/openStore'
import { toggleBodyClass } from 'utils/bodyClass'
import { useInterval } from 'utils/useInterval'
import Maps from 'view/components/maps/Maps'
import Search from 'view/components/search/Search'
import CookieBanner from 'view/components/cookie-banner/CookieBanner'
import Analytics from 'view/components/analytics/Analytics'
import ScrollBlock from './components/scroll-block/ScrollBlock'

import Calendar from 'view/content/calendar/Calendar'
import CalendarEvent from 'view/content/calendar/CalendarEvent'
import Detail from 'view/content/detail/DetailProvider'
import Home from 'view/content/home/Home'
import Index from 'view/content/index/Index'
import Page from 'view/content/page/PageProvider'

import 'style/globals/index.scss'

const App = (): ReactElement => {
  const location = useLocation()
  const state = location.state as { backgroundLocation?: Location }

  const addDarkModeOnTimeWindow = () => {
    const isNight = isTimeBetween('19:00', '06:00', moment().tz(TIMEZONE))
    toggleBodyClass('dark-mode', isNight)
  }

  // check every 30 seconds, if the dark mode should be activated
  useInterval(() => {
    addDarkModeOnTimeWindow()
  }, 30 * 1000)

  // add dark-mode class initially if reasonable
  useEffect(() => addDarkModeOnTimeWindow(), [])

  return (
    <main>
      <Analytics />
      <CookieBanner />

      <AnimatePresence>
        {state?.backgroundLocation && (
          <Routes location={location}>
            <Route path="/detail/:slug" element={<Detail />} />
            <Route path="/:slug" element={<Page />} />
          </Routes>
        )}
      </AnimatePresence>

      <AnimatePresence>
        <Routes
          location={state?.backgroundLocation || location}
          key={state?.backgroundLocation.pathname || location.pathname}
        >
          <Route path="/" element={<Home />} />
          <Route path="/index" element={<Index />} />
          <Route path="/kalender" element={<Calendar />} />
          <Route path="/event/:slug" element={<CalendarEvent />} />
          <Route path="/detail/:slug" element={<Detail />} />
          <Route path="/:slug" element={<Page />} />
        </Routes>
      </AnimatePresence>

      <Maps />
      <Search />
      <ScrollBlock />
    </main>
  )
}

export default App
