import React, { ReactElement, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import Header from 'view/components/header/Header'
import Helmet from 'view/components/helmet/DefaultHelmet'
import CalendarSection from './CalendarSection'
import Spacer from 'view/components/spacer/Spacer'

import './Calendar.scss'

const mapStateToProps = (state: RootState) => ({
  sections: state.events.sections,
  members: state.members.items,
})

const connector = connect(mapStateToProps)

type CalendarProps = ConnectedProps<typeof connector>

const Calendar = ({ sections, members }: CalendarProps): ReactElement => {
  // scroll to top of the page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const helmetParams = {
    title: 'Kalender',
    description: 'Kalender',
    url: `${BASE_URL}/kalender`,
  }

  const sectionsHtml = Object.keys(sections).map((key) => (
    <CalendarSection
      key={key}
      title={key}
      items={sections[key]}
      members={members}
    />
  ))

  return (
    <>
      <Helmet {...helmetParams} />
      <section className="Calendar">
        <Header scrollAnimation={false} />

        <div className="Calendar__inner">{sectionsHtml}</div>

        <Spacer />
      </section>
    </>
  )
}

export default connector(Calendar)
