import { gql } from '@apollo/client'

const MEMBERS_QUERY = gql`
  query MembersQuery {
    results: entries(section: "members") {
      id
      author: authorId
      slug
      title
      ... on members_member_Entry {
        thumbnail: membersThumbnail {
          id
          url @transform(handle: "large")
          title
          width
          height
        }
        address: membersAddress
        phone: membersPhone
        email: membersEmail
        website: membersWebsite
        facebook: membersFacebook
        instagram: membersInstagram
        openingHoursText: membersOpeningHoursText
        special: membersSpecial
        contents: membersContents {
          type: __typename
          ... on membersContents_image_BlockType {
            id
            hidden: blockHidden
            image: blockImage {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
          }
          ... on membersContents_slideshow_BlockType {
            id
            hidden: blockHidden
            images: blockImages {
              id
              url @transform(handle: "large")
              title
              width
              height
            }
          }
          ... on membersContents_text_BlockType {
            id
            hidden: blockHidden
            indent: blockIndent
            title: blockTitle
            content: blockContent
          }
          ... on membersContents_textSmall_BlockType {
            id
            hidden: blockHidden
            title: blockTitle
            content: blockContent
          }
        }
        lat: membersLatitude
        lng: membersLongitude
        closedFrom: membersClosedFrom
        closedUntil: membersClosedUntil
        openingHours: membersOpeningHours {
          ... on membersOpeningHours_timeSlot_BlockType {
            days: blockDays
            open: blockOpenTime
              @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
            close: blockCloseTime
              @formatDateTime(format: "H.i", timezone: "Europe/Zurich")
          }
        }
        tags: membersTags {
          id
          slug
          title
        }
      }
    }
  }
`

export default MEMBERS_QUERY
