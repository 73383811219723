import React, { Fragment, ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { motion, AnimatePresence } from 'framer-motion'

import { BASE_URL } from 'Constants'
import { PageType } from 'data/types'
import {
  motionOverlayVariants,
  motionOverlayTransition,
} from 'utils/motionVariants'

import DetailNavigation from 'view/components/detail-navigation/DetailNavigation'
import Heading from 'view/components/heading/Heading'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Image from 'view/components/image/Image'
import Meta from 'view/components/meta/Meta'
import Share from 'view/components/share/Share'
import Slideshow from 'view/components/slideshow/Slideshow'
import Text from 'view/components/text/Text'

import './Page.scss'

type PageProps = {
  slug: string
  item: PageType
  showShare: boolean
  actionUIToggle: (type: string, open: boolean) => void
}

const Page = ({
  slug,
  item,
  showShare,
  actionUIToggle,
}: PageProps): ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()

  const state = location.state as { backgroundLocation?: Location }
  const { title, thumbnail, contents } = item

  const actionBackButton = () => {
    // navigate back or home
    if (state?.backgroundLocation) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }

  // 1. intro img
  const introImg = thumbnail && thumbnail[0] && (
    <div className="Page__intro">
      <div className="Page__intro__ratio"></div>
      <Image image={thumbnail[0]} className="Page__intro__img" />
    </div>
  )

  // 2. content blocks
  let helmetDescription = ''
  const blocks =
    contents &&
    contents.map((item: any) => {
      const { id, type, hidden } = item

      if (hidden) {
        return null
      }

      if (type === 'pagesContents_text_BlockType') {
        if (helmetDescription === '') {
          helmetDescription = item.content
        }

        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={item.indent} />
            </Fragment>
          )
        } else {
          return <Text key={id} content={item.content} indent={item.indent} />
        }
      } else if (type === 'pagesContents_textSmall_BlockType') {
        if (helmetDescription === '') {
          helmetDescription = item.content
        }

        if (item.title) {
          return (
            <Fragment key={id}>
              <Heading title={item.title} />
              <Text content={item.content} indent={false} size="tiny" />
            </Fragment>
          )
        } else {
          return (
            <Text key={id} content={item.content} indent={false} size="tiny" />
          )
        }
      } else if (type === 'pagesContents_meta_BlockType') {
        return <Meta key={id} content={item.content} />
      } else if (type === 'pagesContents_special_BlockType') {
        return <Meta content={`Special<br />${item.content}`} special={true} />
      } else if (
        type === 'pagesContents_image_BlockType' &&
        item.image.length > 0
      ) {
        return (
          <figure key={id} className="Page__contents__figure">
            <div
              className="Page__contents__figure__ratio"
              style={{
                paddingTop: `${
                  (item.image[0].height / item.image[0].width) * 100
                }%`,
              }}
            ></div>

            <Image
              image={item.image[0]}
              className="Page__contents__figure__img"
            />
          </figure>
        )
      } else if (type === 'pagesContents_slideshow_BlockType') {
        return <Slideshow key={id} images={item.images} />
      }

      return null
    })

  const helmetParams = {
    title,
    description: helmetDescription,
    url: `${BASE_URL}/detail/${slug}`,
    images: thumbnail,
  }

  // handle body scroll lock (with iOS15 hack)
  const onAnimationStart = (definition: string) => {
    if (definition === 'exit') {
      actionUIToggle('page', false)
    }
  }

  const onAnimationComplete = (definition: string) => {
    if (definition === 'visible') {
      actionUIToggle('page', true)
    }
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <motion.section
        className="Page"
        variants={motionOverlayVariants}
        transition={motionOverlayTransition}
        initial="hidden"
        animate="visible"
        exit="exit"
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
      >
        <div className="Page__inner">
          {introImg}

          <div className="Page__title">{title}</div>

          <div className="Page__contents">{blocks}</div>
        </div>
      </motion.section>
      <DetailNavigation
        actionBackButton={actionBackButton}
        actionUIToggle={actionUIToggle}
      />
      <AnimatePresence>
        {showShare && (
          <Share
            actionUIToggle={actionUIToggle}
            title={title}
            tags=""
            url={`${BASE_URL}/${slug}`}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Page
