import React, { ReactElement, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { membersShuffleTagsItems } from 'data/redux/members/actions'
import Header from 'view/components/header/Header'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Spacer from 'view/components/spacer/Spacer'
import IndexSlideshow from './IndexSlideshow'

import './Index.scss'

const mapStateToProps = (state: RootState) => ({
  tags: state.members.tags,
  proximity: state.members.proximity,
})

const mapDispatchToProps = {
  actionShuffleTagsItems: membersShuffleTagsItems,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type IndexProps = ConnectedProps<typeof connector>

const Index = ({
  tags,
  proximity,
  actionShuffleTagsItems,
}: IndexProps): ReactElement => {
  // scroll top and shuffle tag items, if not proximity
  useEffect(() => {
    window.scrollTo(0, 0)
    actionShuffleTagsItems()
  }, [])

  const helmetParams = {
    title: 'Index',
    description: 'Index',
    url: `${BASE_URL}/index`,
  }

  const sections = tags.map((section) => {
    const { id, title, items, total } = section

    return (
      <div key={id} className="Index__section">
        <div className="Index__section__header">
          <div className="Index__section__header__title">{title}</div>
          <div className="Index__section__header__count">({total})</div>
        </div>

        <IndexSlideshow items={items} proximity={proximity} />
      </div>
    )
  })

  return (
    <>
      <Helmet {...helmetParams} />
      <section className="Index">
        <Header scrollAnimation={false} />

        <div className="Index__inner">{sections}</div>

        <Spacer />
      </section>
    </>
  )
}

export default connector(Index)
