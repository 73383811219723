import React, { ReactElement, RefObject, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { animateScroll } from 'react-scroll'
import classnames from 'classnames'
import { motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { uiSelectTag } from 'data/redux/ui/actions'
import { posTop, posTopTotal } from 'utils/positioning'
import { hasBodyClass } from 'utils/bodyClass'
import { motionTagsSticky } from 'utils/motionVariants'
import { useIsomorphicLayoutEffect } from 'utils/useIsomorphicLayoutEffect'
import IconClose from 'view/components/icons/IconClose'

import './TagsSticky.scss'

const mapStateToProps = (state: RootState) => ({
  items: state.members.tags,
  selectedTag: state.ui.selectedTag,
})

const mapDispatchToProps = {
  actionSelectTag: uiSelectTag,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface TagsStickyProps extends PropsFromRedux {
  refBarrier: RefObject<HTMLDivElement>
}

const TagsSticky = ({
  items,
  selectedTag,
  refBarrier,
  actionSelectTag,
}: TagsStickyProps): ReactElement => {
  const [isSticky, setSticky] = useState(false)

  useIsomorphicLayoutEffect(() => {
    const onScroll = () => {
      const barrierTop = posTop(refBarrier.current)
      const limitTop = hasBodyClass('has-cookie-banner') ? 22 + 48 : 48

      setSticky(barrierTop <= limitTop)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [refBarrier])

  const classes = classnames('TagsSticky', {
    'TagsSticky--sticky': isSticky,
  })

  const tags = items.map((item) => {
    const { id, title, slug } = item
    const active = selectedTag === slug
    const tagClick = () => {
      if (active) {
        actionSelectTag('')
      } else {
        actionSelectTag(slug)
      }

      if (refBarrier && refBarrier.current) {
        animateScroll.scrollTo(posTopTotal(refBarrier.current) - 34, {
          delay: 0,
          duration: 600,
          smooth: 'easeOutCubic',
          ignoreCancelEvents: true,
        })
      }
    }

    const classes = classnames('TagsSticky__list__item__button', {
      'TagsSticky__list__item__button--active': active,
    })

    return (
      <li key={id} className="TagsSticky__list__item">
        <button className={classes} onClick={tagClick}>
          {active && (
            <span className="TagsSticky__list__item__button__close">
              <IconClose />
            </span>
          )}
          <span className="TagsSticky__list__item__button__title">{title}</span>
        </button>
      </li>
    )
  })

  return (
    <motion.nav
      variants={motionTagsSticky}
      className={classes}
      initial="hidden"
      animate={isSticky ? 'visible' : 'hidden'}
    >
      <div className="TagsSticky__inner">
        <ul className="TagsSticky__list">{tags}</ul>
      </div>
    </motion.nav>
  )
}

export default connector(TagsSticky)
