import React, { ReactElement } from 'react'

import './Icons.scss'

const IconShare = (): ReactElement => (
  <svg className="IconShare" viewBox="0 0 20 20">
    <polyline points="14,8.3 16.2,8.3 16.8,8.3 16.8,18.7 16.2,18.7 4,18.7 3.4,18.7 3.4,8.3 4,8.3 6.3,8.3" />
    <polyline points="6.8,4.1 10.1,0.7 13.5,4.1" />
    <line x1="10.1" y1="0.7" x2="10.1" y2="11" />
    <line x1="10.1" y1="0.7" x2="13.5" y2="4.1" />
  </svg>
)

export default IconShare
