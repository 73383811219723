import React, { ReactElement, useEffect, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BASE_URL } from 'Constants'
import {
  HomepageContentsFeaturedBlockType,
  HomepageContentsSpecialBlockType,
} from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import { membersShuffleHomeItems } from 'data/redux/members/actions'
import Header from 'view/components/header/Header'
import Helmet from 'view/components/helmet/DefaultHelmet'
import Preview from 'view/components/preview/Preview'
import PreviewEmpty from 'view/components/preview/PreviewEmpty'
import Featured from 'view/components/featured/Featured'
import Special from 'view/components/special/Special'
import Spacer from 'view/components/spacer/Spacer'
import Tags from 'view/components/tags/Tags'
import TagsSticky from 'view/components/tags-sticky/TagsSticky'

import './Home.scss'

const mapStateToProps = (state: RootState) => ({
  homepage: state.homepage,
  itemsHome: state.members.itemsHome,
  tags: state.members.tags,
  selectedTag: state.ui.selectedTag,
  proximity: state.members.proximity,
})

const mapDispatchToProps = {
  actionShuffleHomeItems: membersShuffleHomeItems,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type HomeProps = ConnectedProps<typeof connector>

const Home = ({
  homepage,
  itemsHome,
  tags,
  selectedTag,
  proximity,
  actionShuffleHomeItems,
}: HomeProps): ReactElement => {
  const refHeaderBarrier = useRef(null)
  const refTagsStickyBarrier = useRef(null)

  // scroll top and shuffle home items, if not proximity
  useEffect(() => {
    window.scrollTo(0, 0)
    actionShuffleHomeItems()
  }, [])

  const helmetParams = {
    url: `${BASE_URL}`,
  }

  let i = 0
  const teaser = homepage.contents.map((item) => {
    const { id, type, hidden } = item

    if (hidden || i > 0) {
      return null
    }

    if (type === 'homepageContents_featured_BlockType') {
      const itemTyped = item as HomepageContentsFeaturedBlockType
      const image = itemTyped.image.length > 0 && itemTyped.image[0]
      const member = itemTyped.related.length > 0 && itemTyped.related[0]

      if (member && image) {
        i++
        return <Featured key={id} image={image} {...member} />
      }
    } else if (type === 'homepageContents_special_BlockType') {
      const itemTyped = item as HomepageContentsSpecialBlockType
      const image = itemTyped.image.length > 0 && itemTyped.image[0]
      const related = itemTyped.related.length > 0 && itemTyped.related[0]

      if (image && (related || itemTyped.url)) {
        i++
        return <Special key={id} {...itemTyped} />
      }
    }

    return null
  })

  let hint = null
  let items = null
  let itemsEmpty = null

  if (selectedTag === '') {
    hint = proximity ? 'In deiner Nähe ↓' : 'Auswahl'
    items = itemsHome.map((item) => (
      <Preview key={item.id} proximity={proximity} {...item} />
    ))
  } else {
    const currentTag = tags.find((tag) => tag.slug === selectedTag)

    if (currentTag) {
      const totalItems = currentTag.items.length
      const fillUpCount = 7 - totalItems

      hint = `Tag: ${currentTag.title}`
      items = currentTag.items.map((item) => (
        <Preview key={item.id} proximity={proximity} {...item} />
      ))

      if (fillUpCount > 0) {
        itemsEmpty = [...Array(fillUpCount)].map((e, i) => (
          <PreviewEmpty
            key={`preview-empty-${totalItems + i + 1}`}
            className={`PreviewEmpty--${totalItems + i + 1}`}
          />
        ))
      }
    }
  }

  return (
    <>
      <Helmet {...helmetParams} />
      <section className="Home">
        {teaser}

        <div className="Home__barrier__header" ref={refHeaderBarrier}></div>

        <Header refBarrier={refHeaderBarrier} scrollAnimation={true} />

        <div className="Home__inner">
          <Tags refBarrier={refTagsStickyBarrier} />

          <div className="Home__hint">{hint}</div>
          <div className="Home__barrier__tags" ref={refTagsStickyBarrier}></div>

          <TagsSticky refBarrier={refTagsStickyBarrier} />

          <div className="Home__grid">
            {items}
            {itemsEmpty}
          </div>
        </div>

        <Spacer />
      </section>
    </>
  )
}

export default connector(Home)
