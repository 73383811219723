import { Action, ActionCreator } from 'redux'
import { UI_SELECT_TAG, UI_TOGGLE } from './types'

export const uiSelectTag: ActionCreator<Action> = (tag: string) => ({
  type: UI_SELECT_TAG,
  payload: { tag },
})

export const uiToggle: ActionCreator<Action> = (
  type: string,
  open: boolean
) => ({
  type: UI_TOGGLE,
  payload: { type, open },
})
