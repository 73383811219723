import { AnyAction } from 'redux'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'

import { EventsStateType } from 'data/types'
import { EVENTS_DATA_LOAD } from './types'

const initialState: EventsStateType = {
  items: [],
  sections: [],
}

export default function (
  state = initialState,
  action: AnyAction
): EventsStateType {
  switch (action.type) {
    case EVENTS_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        // group all events by year and month in the format '2022_März'
        const yearAndMonthName = (item) =>
          moment(`${item.date} ${item.openingTime}`, 'DD.MM.YYYY hh:mm')
            .locale('de')
            .format('MMMM_YYYY')

        const sections = groupBy(data, yearAndMonthName)

        return {
          ...state,
          sections,
          items: data,
        }
      }

      return state
    }

    default:
      return state
  }
}
