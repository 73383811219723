import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { IS_SERVER_SIDE } from 'Constants'
import { ReduxExtraArgumentsType } from 'data/types'
import {
  MEMBERS_DATA_LOAD,
  MEMBERS_DISABLE_USER_INTERACTED_WITH_MAPS,
  MEMBERS_GET_USER_LOCATION,
  MEMBERS_GET_USER_LOCATION_FAILED,
  MEMBERS_UPDATE_MAPS_LOCATION,
  MEMBERS_SHUFFLE_HOME_ITEMS,
  MEMBERS_SHUFFLE_TAGS_ITEMS,
} from './types'
import MEMBERS_QUERY from 'data/queries/members'

export const membersDataLoad: ActionCreator<
  ThunkAction<Promise<Action>, any, ReduxExtraArgumentsType, Action>
> = () => {
  return async (
    dispatch: Dispatch,
    getState: any,
    { client }: ReduxExtraArgumentsType
  ) => {
    const response = await client.query({
      query: MEMBERS_QUERY,
    })

    return dispatch({
      type: MEMBERS_DATA_LOAD,
      payload: { data: response.data.results },
    })
  }
}

export const membersGetUserLocation: ActionCreator<
  ThunkAction<Promise<Action>, any, ReduxExtraArgumentsType, Action>
> = () => {
  return async (dispatch: Dispatch, getState: any) => {
    if (!IS_SERVER_SIDE) {
      const geolocation = navigator.geolocation

      if (geolocation) {
        geolocation.watchPosition(
          (position) => {
            dispatch({
              type: MEMBERS_GET_USER_LOCATION,
              payload: position.coords,
            })
          },
          (error) => {
            if (error.code === 1) {
              dispatch({
                type: MEMBERS_GET_USER_LOCATION_FAILED,
                payload: false,
              })
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        )
      } else {
        return dispatch({
          type: MEMBERS_GET_USER_LOCATION_FAILED,
          payload: false,
        })
      }
    } else {
      return dispatch({
        type: MEMBERS_GET_USER_LOCATION_FAILED,
        payload: false,
      })
    }
  }
}

export const membersDisabledUserInteractedWithMaps: ActionCreator<
  Action
> = () => ({
  type: MEMBERS_DISABLE_USER_INTERACTED_WITH_MAPS,
})

export const membersUpdateMapsLocation: ActionCreator<Action> = (
  lat: number,
  lng: number
) => ({
  type: MEMBERS_UPDATE_MAPS_LOCATION,
  payload: { latitude: lat, longitude: lng },
})

export const membersShuffleHomeItems: ActionCreator<Action> = () => ({
  type: MEMBERS_SHUFFLE_HOME_ITEMS,
})

export const membersShuffleTagsItems: ActionCreator<Action> = () => ({
  type: MEMBERS_SHUFFLE_TAGS_ITEMS,
})
