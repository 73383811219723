import React, { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { addBodyClass, removeBodyClass } from 'utils/bodyClass'

import './CookieBanner.scss'

const CookieBanner = (): ReactElement | null => {
  const [accepted, setAccepted] = useState(false)
  const [acceptedCookie, setAcceptedCookie] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const acceptedItem =
      localStorage && localStorage.getItem('tribeka_privacy_accepted')
    const isAccepted = acceptedItem === 'yeah'

    if (!isAccepted) {
      addBodyClass('has-cookie-banner')
    }

    setAcceptedCookie(isAccepted)
  }, [setAcceptedCookie])

  const acceptCookies = () => {
    localStorage.setItem('tribeka_privacy_accepted', 'yeah')
    setAccepted(true)
    removeBodyClass('has-cookie-banner')
  }

  if (!accepted && !acceptedCookie) {
    return (
      <div className="CookieBanner">
        <div className="CookieBanner__inner">
          <span className="CookieBanner__text">
            Privacy Policy: &nbsp;
            <Link to="/privacy" state={{ backgroundLocation: location }}>
              Lesen
            </Link>
          </span>
          &nbsp; / &nbsp;
          <button onClick={acceptCookies} className="CookieBanner__button">
            OK
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default CookieBanner
