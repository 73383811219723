import React, { ReactElement } from 'react'

import './Icons.scss'

const IconCheck = (): ReactElement => (
  <svg className="IconCheck" viewBox="0 0 26 19">
    <polygon points="24.4,0.5 9.3,15.6 1.6,7.8 0.1,9.3 7.9,17 7.8,17 9.3,18.5 25.9,2" />
  </svg>
)

export default IconCheck
