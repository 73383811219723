import React, { ReactElement } from 'react'

import './Icons.scss'

const IconMenu = (): ReactElement => (
  <svg className="IconMenu" viewBox="0 0 20 20">
    <rect y="5.1" width="20" height="2.1" />
    <rect y="12.8" width="20" height="2.1" />
  </svg>
)

export default IconMenu
