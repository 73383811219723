import React, { ReactElement, useEffect, useState, useCallback } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

import { MAPS_API_KEY } from 'Constants'
import { MemberType } from 'data/types'

import { mapStyles } from './mapStyles'

type MapsViewerProps = {
  itemsOpen: MemberType[]
  itemsClosed: MemberType[]
  itemDetail: MemberType | null
  user: {
    lat: number
    lng: number
    locatingEnabled: boolean
  }
  maps: {
    lat: number
    lng: number
  }
  proximity: boolean
  handleMapClick: () => void
  handleMapItemClick: (item: MemberType) => void
  actionUpdateMapsLocation: (lat: number, lng: number) => void
}

const MapsViewer = ({
  itemsOpen,
  itemsClosed,
  itemDetail,
  user,
  maps,
  proximity,
  handleMapClick,
  handleMapItemClick,
  actionUpdateMapsLocation,
}: MapsViewerProps): ReactElement => {
  const [map, setMap] = useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: MAPS_API_KEY,
  })

  const onLoad = useCallback((map) => setMap(map), [])
  const onUnmount = useCallback(() => setMap(null), [])

  const mapCenter = { lat: maps.lat, lng: maps.lng }
  let markerUser = null
  let mapZoom = 16

  // makers
  const markerDefault = {
    path: 'M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z',
    fillColor: '#000000',
    fillOpacity: 0.9,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
  }

  const markerIconSelected = {
    ...markerDefault,
    scale: 1.15,
  }

  const markerIconOpen = {
    ...markerDefault,
    fillColor: '#00ff6e',
  }

  const markerIconClose = {
    ...markerDefault,
    fillColor: '#ff0000',
  }

  const markerIconUser = {
    ...markerDefault,
    fillColor: '#0000ff',
    scale: 1.25,
  }

  if (proximity) {
    mapZoom = 18
    markerUser = (
      <Marker
        position={{ lat: user.lat, lng: user.lng }}
        icon={markerIconUser}
        zIndex={102}
      />
    )
  }

  const markersOpen = itemsOpen.map((item) => {
    const markerOnClick = () => {
      handleMapItemClick(item)
      // map.panTo({ lat: item.lat, lng: item.lng })
    }
    const icon =
      itemDetail && itemDetail.id == item.id
        ? markerIconSelected
        : markerIconOpen

    return (
      <Marker
        key={item.id}
        position={{ lat: item.lat, lng: item.lng }}
        icon={icon}
        zIndex={101}
        onClick={markerOnClick}
      />
    )
  })

  const markersClosed = itemsClosed.map((item) => {
    const markerOnClick = () => {
      handleMapItemClick(item)
      // map.panTo({ lat: item.lat, lng: item.lng })
    }
    const icon =
      itemDetail && itemDetail.id == item.id
        ? markerIconSelected
        : markerIconClose

    return (
      <Marker
        key={item.id}
        position={{ lat: item.lat, lng: item.lng }}
        icon={icon}
        zIndex={100}
        onClick={markerOnClick}
      />
    )
  })

  const mapsOnClick = () => handleMapClick()

  // pass center of map to redux store
  // so it can make the new list of items
  // on drag
  const mapsOnDragEnd = () => {
    if (map) {
      actionUpdateMapsLocation(map.center.lat(), map.center.lng())
    }
  }

  // useEffect(() => {
  //   if (itemDetail !== null && map) {
  //     map.panTo({ lat: itemDetail.lat, lng: itemDetail.lng })
  //   }
  // }, [itemDetail])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
      center={mapCenter}
      zoom={mapZoom}
      options={{
        mapTypeId: 'roadmap',
        styles: mapStyles,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={mapsOnClick}
      onDragEnd={mapsOnDragEnd}
    >
      {markersOpen}
      {markersClosed}
      {markerUser}
    </GoogleMap>
  ) : (
    <div>Karte wird geladen</div>
  )
}

export default MapsViewer
