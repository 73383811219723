import React, { ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Header from 'view/components/header/Header'
import Spacer from 'view/components/spacer/Spacer'

import './NotFound.scss'

const NotFound = (): ReactElement => {
  // scroll to top of the page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section className="NotFound">
      <Header scrollAnimation={false} />

      <div className="NotFound__inner">
        Die Seite konnte nicht gefunden werden.
        <br />
        <Link to="/">Zurück zur Startseite.</Link>
      </div>

      <Spacer />
    </section>
  )
}

export default NotFound
