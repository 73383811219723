import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'

import { motionButtonDetailNavigationVariants } from 'utils/motionVariants'
import IconClose from 'view/components/icons/IconClose'
import IconShare from 'view/components/icons/IconShare'

import './DetailNavigation.scss'

type DetailNavigationProps = {
  actionBackButton: () => void
  actionUIToggle: (type: string, open: boolean) => void
}

const DetailNavigation = ({
  actionBackButton,
  actionUIToggle,
}: DetailNavigationProps): ReactElement => {
  const handleBackButton = () => actionBackButton()
  const handleShareOpenClick = () => actionUIToggle('share', true)

  return (
    <>
      <motion.button
        variants={motionButtonDetailNavigationVariants}
        className="DetailNavigation__close"
        initial="hidden"
        animate="visible"
        exit="hidden"
        whileHover="hover"
        whileTap="tap"
        onClick={handleBackButton}
      >
        <IconClose />
      </motion.button>

      <motion.button
        variants={motionButtonDetailNavigationVariants}
        className="DetailNavigation__share"
        initial="hidden"
        animate="visible"
        exit="hidden"
        whileHover="hover"
        whileTap="tap"
        onClick={handleShareOpenClick}
      >
        <IconShare />
      </motion.button>
    </>
  )
}

export default DetailNavigation
