import { AnyAction } from 'redux'

import { PagesStateType } from 'data/types'
import { PAGES_DATA_LOAD } from './types'

const initialState: PagesStateType = {
  items: [],
}

export default function (
  state = initialState,
  action: AnyAction
): PagesStateType {
  switch (action.type) {
    case PAGES_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        return {
          ...state,
          items: data,
        }
      }

      return state
    }

    default:
      return state
  }
}
