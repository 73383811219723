import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { ImageType, TagsType } from 'data/types'

import './Featured.scss'

type FeaturedProps = {
  slug: string
  title: string
  tags: TagsType[]
  image: ImageType
}

const Featured = ({
  slug,
  title,
  tags,
  image,
}: FeaturedProps): ReactElement => {
  const tagsText = tags.map((item) => item.title).join(', ')

  return (
    <aside className="Featured">
      <Link className="Featured__link" to={`detail/${slug}`}>
        <div className="Featured__inner">
          <div className="Featured__top">
            <div className="Featured__top__bar"></div>
            <div className="Featured__top__meta">Featured</div>
          </div>
          <div className="Featured__title">
            <div className="Featured__title__inner">{title}</div>
          </div>
          <div className="Featured__tags">{tagsText}</div>
        </div>
        <img className="Featured__bg" src={image.url} />
      </Link>
    </aside>
  )
}

export default Featured
