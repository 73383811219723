import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'

import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'
import {
  motionNavigationSmallVariants,
  motionNavigationSmallButtonVariants,
  motionButtonVariants,
} from 'utils/motionVariants'
import IconClose from 'view/components/icons/IconClose'

import './NavigationSmall.scss'

const mapStateToProps = (state: RootState) => ({
  navigationItems: state.navigation.items,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type NavigationSmallProps = ConnectedProps<typeof connector>

const NavigationSmall = ({
  navigationItems,
  actionUIToggle,
}: NavigationSmallProps): ReactElement => {
  const location = useLocation()
  const closeNavigationSmallDialogClick = () =>
    actionUIToggle('navigationSmall', false)

  const handleSearchOpen = () => actionUIToggle('search', true)
  const handleLinkOnClick = () => actionUIToggle('navigationSmall', false)
  const handleExternalLinkOnClick = (url) => () => {
    window.open(url)
    actionUIToggle('navigationSmall', false)
  }

  const items = navigationItems.map((item) => {
    const { id, type, title } = item

    if (type === 'navigation_externalLink_Entry' && item.url) {
      return (
        <motion.li
          key={id}
          variants={motionNavigationSmallButtonVariants}
          initial="initial"
          whileHover="hover"
          whileTap="tap"
          className="NavigationSmall__list__item"
        >
          <button
            className="NavigationSmall__list__item__button"
            onClick={handleExternalLinkOnClick(item.url)}
          >
            {title}
          </button>
        </motion.li>
      )
    } else if (type === 'navigation_navigationItem_Entry') {
      const related = item.related.length > 0 && item.related[0]

      if (related) {
        return (
          <motion.li
            key={id}
            variants={motionNavigationSmallButtonVariants}
            initial="initial"
            whileHover="hover"
            whileTap="tap"
            className="NavigationSmall__list__item"
          >
            <NavLink
              className={({ isActive }) =>
                `NavigationSmall__list__item__link${
                  isActive ? ' NavigationSmall__list__item__link--active' : ''
                }`
              }
              to={`/${related.slug}`}
              state={
                related.handle === 'page'
                  ? { backgroundLocation: location }
                  : null
              }
              onClick={handleLinkOnClick}
            >
              {title}
            </NavLink>
          </motion.li>
        )
      }
    }

    return null
  })

  const homeLink = (
    <motion.li
      variants={motionNavigationSmallButtonVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="NavigationSmall__list__item"
    >
      <NavLink
        className={({ isActive }) =>
          `NavigationSmall__list__item__link${
            isActive ? ' NavigationSmall__list__item__link--active' : ''
          }`
        }
        to="/"
        onClick={handleLinkOnClick}
      >
        Home
      </NavLink>
    </motion.li>
  )

  const searchLink = (
    <motion.li
      variants={motionNavigationSmallButtonVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap"
      className="NavigationSmall__list__item"
    >
      <button
        onClick={handleSearchOpen}
        className="NavigationSmall__list__item__button"
      >
        Suche
      </button>
    </motion.li>
  )

  return (
    <motion.nav
      variants={motionNavigationSmallVariants}
      className="NavigationSmall"
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <button
        className="NavigationSmall__outerclose"
        onClick={closeNavigationSmallDialogClick}
      />

      <div className="NavigationSmall__inner">
        <div className="NavigationSmall__title">Menu</div>
        <ul className="NavigationSmall__list">
          {homeLink}
          {items}
          {searchLink}
        </ul>
        <motion.button
          variants={motionButtonVariants}
          className="NavigationSmall__close"
          whileHover="hover"
          whileTap="tap"
          onClick={closeNavigationSmallDialogClick}
        >
          <IconClose />
        </motion.button>
      </div>
    </motion.nav>
  )
}

export default connector(NavigationSmall)
