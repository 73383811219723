import React, { ReactElement } from 'react'

import SpacerVideo from './SpacerVideo.mp4'

import './Spacer.scss'

const Spacer = (): ReactElement => {
  return (
    <div className="Spacer">
      <video className="Spacer__video" muted loop playsInline autoPlay>
        <source src={SpacerVideo} type="video/mp4" />
      </video>
    </div>
  )
}

export default Spacer
