import React, { ReactElement, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { MemberType } from 'data/types'
import Image from 'view/components/image/Image'

import './Preview.scss'

type PreviewCustomLinkProps = {
  children: ReactNode
  slug: string
  clickAction?: () => void
}

const PreviewCustomLink = ({
  children,
  slug,
  clickAction,
}: PreviewCustomLinkProps): ReactElement => {
  const location = useLocation()

  if (clickAction) {
    const onClick = (ev) => {
      if (!ev.defaultPrevented) {
        clickAction()
      }
    }

    return (
      <button onClick={onClick} className="Preview__link__button">
        {children}
      </button>
    )
  }

  return (
    <Link
      className="Preview__link"
      to={`/detail/${slug}`}
      state={{ backgroundLocation: location }}
    >
      {children}
    </Link>
  )
}

interface PreviewProps extends MemberType {
  className?: string
  proximity: boolean
  clickAction?: () => void
}

const Preview = ({
  slug,
  title,
  thumbnail,
  tags,
  open,
  distance,
  className,
  proximity,
  clickAction,
}: PreviewProps): ReactElement => {
  const tagsText = tags.map((item) => item.title).join(', ')
  const img = thumbnail && thumbnail.length > 0 && (
    <figure className="Preview__figure">
      <Image image={thumbnail[0]} className="Preview__figure__img" />
    </figure>
  )

  let distanceText = null

  if (distance && proximity) {
    distanceText = `→ ${Math.round(distance)}M`
  }

  const svgCircle = (
    <svg version="1.1" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="6" />
    </svg>
  )

  const availablity =
    open === null ? null : open ? (
      <>
        <span className="Preview__availability__open">{svgCircle}</span>
        <span className="Preview__availability__text">Open</span>
      </>
    ) : (
      <>
        <span className="Preview__availability__closed">{svgCircle}</span>
        <span className="Preview__availability__text">Closed</span>
      </>
    )

  const classes = classnames('Preview', className)

  return (
    <article className={classes}>
      <PreviewCustomLink slug={slug} clickAction={clickAction}>
        <div className="Preview__inner">
          <div className="Preview__ratio"></div>

          {img}

          <div className="Preview__title">
            <div className="Preview__title__inner">{title}</div>
          </div>

          <div className="Preview__tags">{tagsText}</div>

          {distanceText && (
            <div className="Preview__distance">{distanceText}</div>
          )}

          {availablity && (
            <div className="Preview__availability">{availablity}</div>
          )}
        </div>
      </PreviewCustomLink>
    </article>
  )
}

export default Preview
