import React, { ReactElement } from 'react'
import { useParams } from 'react-router'
import { connect, ConnectedProps } from 'react-redux'

import { DetailParamsType } from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'

import NotFound from 'view/content/not-found/NotFound'
import Detail from './Detail'

const mapStateToProps = (state: RootState) => ({
  members: state.members.items,
  proximity: state.members.proximity,
  showShare: state.ui.showShare,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type DetailProviderProps = ConnectedProps<typeof connector>

const DetailProvider = ({
  members,
  proximity,
  showShare,
  actionUIToggle,
}: DetailProviderProps): ReactElement => {
  const { slug } = useParams<DetailParamsType>()
  const member = members.find((member) => member.slug === slug)

  if (member) {
    return (
      <Detail
        slug={slug}
        member={member}
        proximity={proximity}
        showShare={showShare}
        actionUIToggle={actionUIToggle}
      />
    )
  }

  return <NotFound />
}

export default connector(DetailProvider)
