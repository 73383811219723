import React, { ReactElement } from 'react'

import './Icons.scss'

const IconClose = (): ReactElement => (
  <svg className="IconClose" viewBox="0 0 20 20">
    <path d="M2,2l16,16 M18,2L2,18" />
  </svg>
)

export default IconClose
