import React, { ReactElement, RefObject } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { animateScroll } from 'react-scroll'
import classnames from 'classnames'

import { CATEGORY_MAX } from 'Constants'
import { RootState } from 'data/redux/rootReducer'
import { uiSelectTag } from 'data/redux/ui/actions'
import { posTopTotal } from 'utils/positioning'

import './Tags.scss'

const mapStateToProps = (state: RootState) => ({
  items: state.members.tags,
  selectedTag: state.ui.selectedTag,
})

const mapDispatchToProps = {
  actionSelectTag: uiSelectTag,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface TagsProps extends PropsFromRedux {
  refBarrier: RefObject<HTMLDivElement>
}

const Tags = ({
  items,
  selectedTag,
  actionSelectTag,
  refBarrier,
}: TagsProps): ReactElement => {
  const resetButtonClick = () => {
    actionSelectTag('')

    if (refBarrier && refBarrier.current) {
      animateScroll.scrollTo(posTopTotal(refBarrier.current) - 34, {
        delay: 0,
        duration: 600,
        smooth: 'easeOutCubic',
        ignoreCancelEvents: true,
      })
    }
  }

  const tags = items.slice(0, parseInt(CATEGORY_MAX, 10)).map((item) => {
    const { id, title, slug } = item
    const active = selectedTag === slug
    const tagClick = () => {
      if (active) {
        actionSelectTag('')
      } else {
        actionSelectTag(slug)
      }

      if (refBarrier && refBarrier.current) {
        animateScroll.scrollTo(posTopTotal(refBarrier.current) - 34, {
          delay: 0,
          duration: 600,
          smooth: 'easeOutCubic',
          ignoreCancelEvents: true,
        })
      }
    }

    const classes = classnames('Tags__list__item__button', {
      'Tags__list__item__button--active': active,
    })

    return (
      <li key={id} className="Tags__list__item">
        <button className={classes} onClick={tagClick}>
          {title}
        </button>
      </li>
    )
  })

  const resetButton =
    selectedTag === '' ? null : (
      <div className="Tags__list__reset">
        <button
          className="Tags__list__reset__button"
          onClick={resetButtonClick}
        >
          Reset
        </button>
      </div>
    )

  return (
    <nav className="Tags">
      <div className="Tags__inner">
        <ul className="Tags__list">
          {tags}
          {resetButton}
        </ul>
      </div>
    </nav>
  )
}

export default connector(Tags)
