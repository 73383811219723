import React, { ReactElement } from 'react'
import { useParams } from 'react-router'
import { connect, ConnectedProps } from 'react-redux'

import { PageParamsType } from 'data/types'
import { RootState } from 'data/redux/rootReducer'
import { uiToggle } from 'data/redux/ui/actions'

import NotFound from 'view/content/not-found/NotFound'
import Page from './Page'

const mapStateToProps = (state: RootState) => ({
  items: state.pages.items,
  showShare: state.ui.showShare,
})

const mapDispatchToProps = {
  actionUIToggle: uiToggle,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PageProviderProps = ConnectedProps<typeof connector>

const PageProvider = ({
  items,
  showShare,
  actionUIToggle,
}: PageProviderProps): ReactElement => {
  const { slug } = useParams<PageParamsType>()
  const item = items.find((item) => item.slug === slug)

  if (item) {
    return (
      <Page
        slug={slug}
        item={item}
        showShare={showShare}
        actionUIToggle={actionUIToggle}
      />
    )
  }

  return <NotFound />
}

export default connector(PageProvider)
