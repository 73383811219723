import React, {
  MouseEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper'
import classnames from 'classnames'

import { ImageType } from 'data/types'
import Image from 'view/components/image/Image'
import DragCursor from 'view/components/drag-cursor/DragCursor'

import './Slideshow.scss'

type SlideshowProps = {
  images: ImageType[]
}

const Slideshow = ({ images }: SlideshowProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const refSwiper = useRef<any>(null)
  const [hover, setHover] = useState(false)
  const [mouseCoords, setMouseCoords] = useState<[number, number]>([0, 0])

  const onMouseOver = () => setHover(true)
  const onMouseLeave = () => setHover(false)
  const onMouseMove = (ev: MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      const mouseCoords: [number, number] = [
        ev.clientX - bounds.left,
        ev.clientY - bounds.top,
      ]

      setMouseCoords(mouseCoords)
    }
  }
  const onFocus = () => void 0

  useEffect(() => {
    if (refSwiper.current !== null && refSwiper.current.swiper !== null) {
      refSwiper.current.swiper.init()
    }
  }, [])

  const onLoad = () => {
    if (refSwiper.current !== null && refSwiper.current.swiper !== null) {
      refSwiper.current.swiper.update()
    }
  }

  const items = images.map(
    (image) =>
      image && (
        <Image
          key={image.id}
          image={image}
          className={classnames('Slideshow__img', {
            'Slideshow__img--landscape': image.height / image.width < 1,
          })}
          onLoad={onLoad}
        />
      )
  )

  const swiperParams: ReactIdSwiperProps = {
    freeMode: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  }

  return (
    <div
      className="Slideshow"
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onFocus={onFocus}
    >
      <div className="Slideshow__inner">
        <Swiper ref={refSwiper} {...swiperParams}>
          {items}
        </Swiper>
        <div className="Slideshow__bullets"></div>
        {hover && <DragCursor coords={mouseCoords} />}
      </div>
    </div>
  )
}

export default Slideshow
