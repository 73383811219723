import React, { ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import striptags from 'striptags'

import { ImageType, HomepageContentsSpecialBlockRelatedType } from 'data/types'

import './Special.scss'

type CustomLinkProps = {
  children: ReactNode
  url: string
  related: HomepageContentsSpecialBlockRelatedType
}

const CustomLink = ({
  children,
  url,
  related,
}: CustomLinkProps): ReactElement => {
  if (related) {
    return (
      <Link className="Special__link" to={`/${related.slug}`}>
        {children}
      </Link>
    )
  } else {
    return (
      <a href={url} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    )
  }
}

type SpecialProps = {
  title: string
  caption: string
  image: ImageType[]
  related: HomepageContentsSpecialBlockRelatedType[]
  url: string | null
}

const Special = ({
  title,
  caption,
  image,
  related,
  url,
}: SpecialProps): ReactElement => {
  const imageFirst = image.length > 0 && image[0]
  const relatedFirst = related.length > 0 && related[0]
  const captionPlain = striptags(caption, ['br'])

  return (
    <aside className="Special">
      <CustomLink related={relatedFirst} url={url}>
        <div className="Special__inner">
          <div className="Special__top">
            <div className="Special__top__bar"></div>
            <div className="Special__top__meta">Special Event</div>
          </div>
          <div
            className="Special__bg"
            style={{ backgroundImage: `url(${imageFirst.url})` }}
          >
            <div
              className="Special__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="Special__caption"
              dangerouslySetInnerHTML={{ __html: captionPlain }}
            />
          </div>
        </div>
      </CustomLink>
    </aside>
  )
}

export default Special
