import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import striptags from 'striptags'
import truncate from 'truncate'

import { motionSearchResultItemVariants } from 'utils/motionVariants'
import Image from 'view/components/image/Image'

import './SearchResultsItemPage.scss'

const SearchResultItemPage = ({ slug, title, thumbnail, contents }) => {
  const img = thumbnail && thumbnail.length > 0 && (
    <figure className="SearchResultsItemPage__img__figure">
      <div className="SearchResultsItemPage__img__figure__ratio" />
      <Image
        image={thumbnail[0]}
        className="SearchResultsItemPage__img__figure__img"
      />
    </figure>
  )

  let descriptionShort = ''

  contents.map((item: any) => {
    const { type, hidden } = item

    if (hidden) {
      return null
    }

    if (type === 'pagesContents_text_BlockType') {
      if (descriptionShort === '') {
        descriptionShort = item.content
      }
    } else if (type === 'pagesContents_textSmall_BlockType') {
      if (descriptionShort === '') {
        descriptionShort = item.content
      }
    }
  })

  const description = truncate(
    striptags(descriptionShort, ['p', 'br', 'strong', 'b', 'italic', 'i']),
    350,
    {
      ellipsis: descriptionShort === '' ? '' : '…',
    }
  )

  return (
    <motion.div
      variants={motionSearchResultItemVariants}
      className="SearchResultsItemPage"
    >
      <Link
        className="SearchResultsItemPage__link"
        to={`/${slug}`}
        // to={{
        //   pathname: `/${slug}`,
        //   state: { overlay: location },
        // }}
      >
        <div className="SearchResultsItemPage__inner">
          <div className="SearchResultsItemPage__img">{img}</div>
          <div className="SearchResultsItemPage__title">{title}</div>
          <div
            className="SearchResultsItemPage__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </Link>
    </motion.div>
  )
}

export default SearchResultItemPage
