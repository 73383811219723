import { AnyAction } from 'redux'

import { HomepageStateType } from 'data/types'
import { HOMEPAGE_DATA_LOAD } from './types'

const initialState: HomepageStateType = {
  id: '',
  contents: [],
}

export default function (
  state = initialState,
  action: AnyAction
): HomepageStateType {
  switch (action.type) {
    case HOMEPAGE_DATA_LOAD: {
      const { data } = action.payload

      if (data) {
        return {
          ...state,
          ...data,
        }
      }

      return state
    }

    default:
      return state
  }
}
